import React, { useState, useEffect } from 'react'
import EmailInput from '../input/EmailInput'
import Button from '../input/Button'
import InputTable from '../input/InputTable'
import UserManager from '../../tools/UserManager'
import Spinner from '../Spinner'
import classNames from '../../tools/classNames'
import {
  tableColumns,
  structureRowData,
  sortUsers,
  sendInvites,
  deleteRow,
  archiveUser,
  updateRole
} from '../../tools/teamManagement'
import { getLocations } from '../../tools/locationManagement'

const roleMap = {
  'admin': 'Admin',
  'member': 'Member',
  'operations': 'Operations',
  'warehouse': 'Warehouse',
  'driver': 'Driver',
  'install-crew': 'Install Crew'
}

/**
 * 
 * @param {function} setNextStepButtonText - function to set the text of the next step button
 * @param {function} setNextStepButtonDisabled - function to disable/enable the next step button
 * @param {function} setGoToToaButtonDisabled - function to disable/enable the go to toa button
 * @param {function} setError - function to set an error message
 * @param {object} company - object representing the current user's company
 * @param {array} users - list of users in the company
 * @param {function} setUsers - function to set the list of users
 * 
 * @returns - a component that allows the user to invite coworkers to the company and manage their roles
 */
export default function Roles({
  setNextStepButtonText = () => { },
  setNextStepButtonDisabled = () => { },
  setGoToToaButtonDisabled = () => { },
  setError = () => { },
  company
}) {

  // current users in the table
  const [users, setUsers] = useState([])

  const [emailsToAdd, setEmailsToAdd] = useState([])
  const [loading, setLoading] = useState(false)

  const [columns, setColumns] = useState([])

  useEffect(() => {
    tableColumns()
      .then((columns) => {
        setColumns(columns);
      })
  }, [])

  // when the page loads, get all users and invites for the company and add them to the table
  useEffect(() => {
    // get current users and invites
    UserManager.makeAuthenticatedRequest(
      "/api/user/list",
      "GET"
    ).then(async (res) => {

      const companyType = await UserManager.getUser(true)
        .then(user => user.userData.company.type)

      var locations;
      if (companyType === 'distributor') {
        locations = await getLocations(setError)
      }

      // append user invites to users
      let newUsers = [...users]
      res.data.invites.forEach(invite => {
        if (newUsers.find(user => user.email === invite.email)) {
          return
        }
        newUsers.push(structureRowData({
          id: invite.email,
          name: '--',
          email: invite.email,
          status: invite.status === 'pending' ? 'Pending Invite' : invite.status,
          role: roleMap[invite.role] ? roleMap[invite.role] : 'None',
          ...(companyType === 'distributor' &&
          {
            location: locations.find(location => location._id === invite.location)?.name
              ? locations.find(location => location._id === invite.location)?.name
              : 'None'
          }
          ),
          rowStatus: 'readonly',
          menuOptions: [
            { label: 'Delete', value: 'delete', onSelected: (item) => deleteRow(item, setError) },
            { label: 'Edit', value: 'edit' }
          ]
        }))
      })

      res.data.users.forEach(user => {
        if (newUsers.find(u => u.email === user.email)) {
          return
        }

        newUsers.push(structureRowData({
          id: user.email,
          name: user.firstName + ' ' + user.lastName,
          email: user.email,
          status: 'Active',
          role: roleMap[user.company.role] ? roleMap[user.company.role] : 'None',
          ...(companyType === 'distributor' &&
          {
            location: locations.find(location => location._id === user.company.location)?.name
              ? locations.find(location => location._id === user.company.location)?.name
              : 'None'
          }),
          rowStatus: 'readonly',
          menuOptions: [
            { label: 'Archive', value: 'archive', onSelected: (item) => archiveUser(item, setError, setUsers) },
            { label: 'Edit', value: 'edit' }
          ]
        }))
      })

      newUsers = sortUsers(newUsers)

      setUsers(newUsers)
    });
  }, [])


  function handleInviteButtonClicked() {
    setGoToToaButtonDisabled(true)
    sendInvites(users, setUsers, emailsToAdd, setEmailsToAdd, 'Member', setError)
    setGoToToaButtonDisabled(false)
  }


  var inviteButton = (
    loading ?
      <div className='flex w-[146px] ml-4 items-center align-middle'>
        <Spinner />
      </div>
      :
      <Button
        variant='primary-green' className='ml-4'
        onClick={() => {
          handleInviteButtonClicked()
        }}
      >
        Invite Coworkers
      </Button>
  )

  return (
    <div className='flex flex-col items-center justify-start w-full mt-4'>
      <div className='flex flex-col items-center justify-start my-4'>
        <div className='text-xl font-semibold leading-7 text-center align-middle'>
          Invite Coworkers and Manage Roles
        </div>
        <div className='text-base font-normal leading-6 text-center text-gray-500 align-middle'>
          Give permissions to individual team members to manage TOA
        </div>
      </div>

      <div className='flex flex-col items-center justify-start mt-4'>
        <div className='w-full px-1 my-2 text-sm font-medium leading-5 text-left align-middle'>
          Invite additional coworkers to {company.name}
        </div>
        <div className='h-24'>
          <div className='flex flex-row items-start justify-center'>
            <div className='w-96'>
              <EmailInput
                emails={emailsToAdd}
                setEmails={setEmailsToAdd}
                className='!min-h-12 !max-h-24'
              />
            </div>
            <div className='py-1.5'>
              {inviteButton}
            </div>
          </div>

        </div>
      </div>

      <div className='w-[1200px]'>
        <InputTable
          tableData={users}
          setTableData={setUsers}
          columns={columns}
          addButtonText={"Add Coworker"}
          outerAddButton={false}
          innerAddButton={false}
          height={'calc(84vh - 400px)'}
          editColumnWidth={4}
          useOnBlur={false}
          onSaveRow={(row) => updateRole(row, setError)}
          onCancelRow={() => {
            setError("")
            setGoToToaButtonDisabled(false)
          }}
        />
      </div>
    </div>
  )
}
