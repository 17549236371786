import moment from "moment";
import {
    CalendarIcon,
    TableCellsIcon
} from "@heroicons/react/24/solid";
import {
    OrderStatus,
    TimestampTypes
} from "src/hooks/data/orders/useOrders.ts";
import React from "react";
import classNames from "../classNames";

/**
 * Formats orders for the Delivery Scorecard table.
 * 
 * @param {Array} orders - list of orders
 * @param {Array} markets - list of markets
 * @returns {Array} - formatted orders
 */
export function formatOrdersForTable(orders, markets) {
    return orders?.map((o, i) => {
        // Build city state string
        var address = null;
        if (o.orderAddress?.line1 || o.orderAddress?.line2 || o.orderAddress?.city || o.orderAddress?.state) {
            address = `${o.orderAddress?.line1 ? o.orderAddress?.line1 : ""}${o.orderAddress?.line2 ? " " + o.orderAddress?.line2 : ""}${o.orderAddress?.city ? ", " + o.orderAddress?.city : ""}${o.orderAddress?.state ? ", " + o.orderAddress?.state : ""}`;
            // trim outer spaces and commas twice
            address = address.trim().replace(/^,|,$/g, "").trim().replace(/^,|,$/g, "");
        }

        var deliveryDate = null;
        if (o.requestedDelivery.deliveryDate) {
            // format like "Thur, June 2"
            deliveryDate = moment(o.requestedDelivery.deliveryDate).format("ddd, MMM D");
        }

        var deliveryWindow = null;
        if (o?.requestedDelivery?.deliveryWindow?.start) {
            // format like "10:00 AM - 12:00 PM"
            const deliveryStart = `${moment(o?.requestedDelivery?.deliveryWindow?.start).format("h:mm A")}`;
            const deliveryEnd = o?.requestedDelivery?.deliveryWindow?.end
                ? `${moment(o?.requestedDelivery?.deliveryWindow?.end).format("h:mm A")}`
                : null;

            if (deliveryStart && deliveryEnd) {
                deliveryWindow = `${deliveryStart} - ${deliveryEnd}`;
            } else {
                deliveryWindow = `${deliveryStart}`;
            }
        }

        deliveryWindow = deliveryDate + " @ " + deliveryWindow;

        // get the most recent timestamp where TimestampTypes.STATUS_CHANGED and oldStatus of IN_TRANSIT and newStatus of DELIVERED, sorted by timestamp.stamp
        const mostRecentDeliveredTimestamp = o.timestamps.filter((t) => {
            return t.type === TimestampTypes.STATUS_CHANGED
                && t.typeInfo.oldStatus === OrderStatus.IN_TRANSIT
                && t.typeInfo.newStatus === OrderStatus.DELIVERED
        }
        ).sort((a, b) => {
            return a.stamp - b.stamp;
        }).pop();

        var deliveredTimestamp = null;
        var timeStatus = null;

        if (mostRecentDeliveredTimestamp) {
            // format t.stamp like "Thur, June 2 @ 10:00 AM"
            deliveredTimestamp = moment(mostRecentDeliveredTimestamp.stamp).format("ddd, MMM D @ h:mm A");

            // check if the timestamp is within the delivery window
            if (
                (o?.requestedDelivery?.deliveryWindow?.end && mostRecentDeliveredTimestamp.stamp <= o.requestedDelivery.deliveryWindow.end)
                || (!o?.requestedDelivery?.deliveryWindow?.end && o?.requestedDelivery?.deliveryWindow?.start && mostRecentDeliveredTimestamp.stamp <= o?.requestedDelivery?.deliveryWindow?.start)
            ) {
                timeStatus = "On Time";
            } else {
                timeStatus = "Late";
            }
        } else {
            deliveredTimestamp = "--";
            timeStatus = "--";
        }

        var materialStatus = "--";

        // Get market
        var market = markets?.find((m) => m._id === o.marketId);
        // TODO: handle null markets

        // Return formatted job
        var formattedOrder = {
            orderId: o._id,
            jobName: o.jobName,
            address: address,
            deliveryWindow: deliveryWindow,
            requestedDeliveryStart: o?.requestedDelivery?.deliveryWindow?.start,
            requestedDeliveryEnd: o?.requestedDelivery?.deliveryWindow?.end,
            deliveryTime: deliveredTimestamp,
            timeStatus: timeStatus,
            materialStatus: materialStatus,
            orderName: o.name,
            marketName: market?.name,
            statusIcons: (
                <div className="flex flex-row items-center justify-center gap-2">
                    <div
                        className={classNames(
                            "flex items-center justify-center rounded-full w-7 h-7",
                            timeStatus === "On Time" ? "bg-primary-green" : "bg-primary-rose"
                        )
                        }
                    >
                        <CalendarIcon className="w-4 h-4 text-white stroke-2" />
                    </div>
                    < div
                        className={
                            classNames(
                                "flex items-center justify-center rounded-full w-7 h-7",
                                materialStatus === "Complete" ? "bg-primary-green" : "bg-primary-rose"
                            )
                        }
                    >
                        <TableCellsIcon className="w-4 h-4 text-white stroke-2" />
                    </div>
                </div>
            )
        };

        return formattedOrder;
    });
}