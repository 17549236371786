import { TruckIcon } from 'lucide-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Dropdown from 'src/components/input/Dropdown'
import Input from 'src/components/input/Input'
import RadioGroup from 'src/components/input/RadioGroup.tsx'
import TimeRangeInput from 'src/components/input/TimeRangeInput.tsx'

type Props = {
  deliveryDateType: DeliveryDateTypes,
  requestedDeliveryDate: string,
  deliveryTimeRangeStart: string,
  deliveryTimeRangeEnd: string,
  selectedRelativeDateOption: "1-day" | "2-day" | "3-day" | ""; // TODO: could fully flush this out or make enum. Not necessary for quick dev.
  installationDate: string

  onDeliveryDateTypeChange: (value: DeliveryDateTypes) => void,
  onRequestedDeliveryDateChange: (value: string) => void,
  onDeliveryTimeRangeStartChange: (value: string) => void,
  onDeliveryTimeRangeEndChange: (value: string) => void,
  onSelectedRelativeDateOptionChange: (value: string) => void
}

export enum DeliveryDateTypes {
  SPECIFIED = 'specified',
  RELATIVE = 'relative'
}

// TODO: comment
export default function DeliveryDateInput({
  deliveryDateType: deliveryDateType_prop,
  requestedDeliveryDate: requestedDeliveryDate_prop,
  deliveryTimeRangeStart: deliveryTimeRangeStart_prop,
  deliveryTimeRangeEnd: deliveryTimeRangeEnd_prop,
  selectedRelativeDateOption: selectedRelativeDateOption_prop,

  onDeliveryDateTypeChange,
  onRequestedDeliveryDateChange,
  onDeliveryTimeRangeStartChange,
  onDeliveryTimeRangeEndChange,
  onSelectedRelativeDateOptionChange,

  installationDate,
}: Props) {
  const [deliveryDateType, setDeliveryDateType] = useState<DeliveryDateTypes>(DeliveryDateTypes.SPECIFIED)

  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<string>('')

  const [deliveryTimeRangeStart, setDeliveryTimeRangeStart] = useState<string>('')
  const [deliveryTimeRangeEnd, setDeliveryTimeRangeEnd] = useState<string>('')

  const [selectedRelativeDateOption, setSelectedRelativeDateOption] = useState<string>('')

  // --- Sync to props --- //
  useEffect(() => setDeliveryDateType(deliveryDateType_prop), [deliveryDateType_prop])
  useEffect(() => setRequestedDeliveryDate(requestedDeliveryDate_prop), [requestedDeliveryDate_prop])
  useEffect(() => setDeliveryTimeRangeStart(deliveryTimeRangeStart_prop), [deliveryTimeRangeStart_prop])
  useEffect(() => setDeliveryTimeRangeEnd(deliveryTimeRangeEnd_prop), [deliveryTimeRangeEnd_prop])
  useEffect(() => setSelectedRelativeDateOption(selectedRelativeDateOption_prop), [selectedRelativeDateOption_prop])
  // --------------------- //

  // Radio group options for delivery date type
  // Specified means a date value is needed.
  // Relative/in advance means the delivery date is
  //   relative to the installation date
  const dateTypeOptions = [
    { id: DeliveryDateTypes.SPECIFIED, label: 'Specified Date' },
    { id: DeliveryDateTypes.RELATIVE, label: 'Days in Advance', disabled: installationDate == '' }
  ]

  // Dropdown options for relative date options
  // Value format: '<value>-<unit>;HH:mm-HH:mm'; (if no time, assume 00:00-23:59)
  const relativeDateOptions = [[
    {
      label: "1 day prior",
      value: "1-day",
    },
    {
      label: "2 days prior",
      value: "2-day",
    },
    {
      label: "3 days prior",
      value: "3-day",
    },
  ]]

  return (
    <div>
      <div className="flex gap-2 items-center">
        <TruckIcon className="w-7 h-7 text-gray-700 stroke-2" />
        <div className="text-gray-700 font-medium text-sm">Delivery Options</div>
        <div className="ml-5">
          <RadioGroup
            name="dateType"
            selectedId={deliveryDateType}
            options={dateTypeOptions}
            onChange={(optionId) => {
              setSelectedRelativeDateOption(''); // Deselect relative date option
              setDeliveryDateType(optionId as DeliveryDateTypes)

              onSelectedRelativeDateOptionChange('')
              onDeliveryDateTypeChange(optionId as DeliveryDateTypes)
            }}
          />
        </div>
      </div>
      {deliveryDateType === DeliveryDateTypes.RELATIVE ?
        <div className="pt-3">
          <Dropdown
            justifyLeft
            wide
            label="Select a day in advance of the installation"
            options={relativeDateOptions}
            selectedValue={selectedRelativeDateOption}
            onSelected={(option) => {

              // Set delivery date accordingly
              let [dateOffset, timeRange] = option.value.split(';')
              let [amount, unit] = dateOffset.split('-')
              let [startTime, endTime] = (timeRange ?? "00:00-23:59").split('-');

              const resultingDate = moment(installationDate).subtract(amount, unit).format("YYYY-MM-DD")
              const resultingStart = moment(startTime, "HH:mm").format("HH:mm:ss.sssZ")
              const resultingEnd = moment(endTime, "HH:mm").format("HH:mm:ss.sssZ")

              setSelectedRelativeDateOption(option.value)
              setRequestedDeliveryDate(resultingDate)
              setDeliveryTimeRangeStart(resultingStart)
              setDeliveryTimeRangeEnd(resultingEnd)

              onSelectedRelativeDateOptionChange(option.value)
              onRequestedDeliveryDateChange(resultingDate)
              onDeliveryTimeRangeStartChange(resultingStart)
              onDeliveryTimeRangeEndChange(resultingEnd)
            }}
          />
        </div> :
        <div className="flex justify-between gap-4 pt-3">
          <div className="grow">
            <Input
              type="date"
              label="Requested Delivery Date"
              name="requestedDeliveryDate"
              value={requestedDeliveryDate}
              onChange={(value) => {
                // right now value is formatted as "YYYY-MM-DD", use moment to format it in date time string format, i.e. "YYYY-MM-DDTHH:mm:ss.sssZ"
                setRequestedDeliveryDate(value ? moment(value).format("YYYY-MM-DD") : value)

                onRequestedDeliveryDateChange(value ? moment(value).format("YYYY-MM-DD") : value)
              }}
            />
          </div>
          <div className="grow">
            <TimeRangeInput
              startTime={deliveryTimeRangeStart ? moment(deliveryTimeRangeStart, "HH:mm:ss.sssZ").format("HH:mm") : ""}
              endTime={deliveryTimeRangeEnd ? moment(deliveryTimeRangeEnd, "HH:mm:ss.sssZ").format("HH:mm") : ""}
              enforceStartBeforeEnd
              onChange={({
                startTime,
                endTime,
              }) => {
                // convert startTime and endTime to date time string f
                // they are currently in the format "HH:MM", so we need to add the date and local timezone
                // format it as "HH:mm:ss.sssZ" with moment
                setDeliveryTimeRangeStart(moment(startTime, "HH:mm").format("HH:mm:ss.sssZ"))
                setDeliveryTimeRangeEnd(moment(endTime, "HH:mm").format("HH:mm:ss.sssZ"))

                onDeliveryTimeRangeStartChange(moment(startTime, "HH:mm").format("HH:mm:ss.sssZ"))
                onDeliveryTimeRangeEndChange(moment(endTime, "HH:mm").format("HH:mm:ss.sssZ"))
              }}
              label="Time Range"
            />
          </div>
        </div>}
    </div>
  )
}
