import moment from 'moment';
import React, { createContext, useContext } from 'react';
import { Navigate, useParams } from 'react-router';
import Card from 'src/components/Card';
import IconDropdown from 'src/components/input/IconDropdown';
import TopBar from 'src/components/nav/TopBar';
import OrderNotes from 'src/components/Orders/common/OrderNotes.tsx';
import { getDeliveryDateString } from 'src/components/Orders/OrderRowItem.tsx';
import OrderTimeline from 'src/components/Orders/OrderTimeline.tsx';
import Spinner from 'src/components/Spinner';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import useOrder from 'src/hooks/data/orders/useOrder.ts';
import { Order } from 'src/hooks/data/orders/useOrders.ts';
import AddressHelper from 'src/utils/addressHelper.ts';

type Props = {}

type OrderDetailsContextType = {
  order: Order | null;
  bomLink: string | null;
  quoteLink: string | null;
}

const OrderDetailsContext = createContext<OrderDetailsContextType>({
  order: null,
  bomLink: null,
  quoteLink: null
})

/**
 * Page for viewing details of a single order. Currently displays:
 * - Metadata like dates, address, contact, PO/SO#, etc.
 * - Notes
 * - Activity timeline
 *
 * Still needs:
 * - Attachments
 * - Photos
 * - Verification 
 * - Issues
 */
export default function OrderDetailsPage({ }: Props) {
  const params = useParams();

  const orderId = params?.id;
  const order = useOrder(orderId)

  const bomLinkArr = useS3URLs(order?.files.billOfMaterials.at(-1)?.filePath ? [order.files.billOfMaterials[0].filePath] : []);
  const quoteLinkArr = useS3URLs(order?.quote?.file?.filePath ? [order.quote.file.filePath] : []);

  const bomLink = bomLinkArr?.length ? bomLinkArr[0].getObjectSignedUrl : null;
  const quoteLink = quoteLinkArr?.length ? quoteLinkArr[0].getObjectSignedUrl : null;

  // Navigate to order list if no order found
  if (!orderId) {
    return <Navigate to="../orders" />
  }

  // Loading state
  if (!order) {
    return <div className="h-screen flex items-center">
      <Spinner />
    </div>
  }

  const orderContextValue = {
    order,
    bomLink,
    quoteLink,
  }

  return (
    <OrderDetailsContext.Provider value={orderContextValue}>
      <TopBar>{order.name}</TopBar>
      <div className="px-8 py-6 flex flex-col lg:flex-row lg:flex-wrap gap-x-5 gap-y-3">
        {/* Full Width Top Section */}
        <div className='w-full'>
          <Metadata />
        </div>

        {/* 2/3 Width Left Section */}
        {/* <div className='lg:grow flex flex-col gap-y-3'> */}
        {/*   <Attachments /> */}
        {/*   <Photos /> */}
        {/* TODO: verification & issues? */}
        {/* </div> */}

        {/* 1/3 Width Left Section */}
        <div className='lg:basis-1/3 flex flex-col gap-y-3'>
          <Notes />
          <Activity />
        </div>

      </div>
    </OrderDetailsContext.Provider>
  )
}

/**
 * Displays the main large card with info about the order.
 * This includes:
 * - Delivery date
 * - Install date
 * - Material (BOM & Quote)
 * - Quote amount
 * - Address
 * - Contact info
 * - PO#, SO#, Loan #
 *
 * Still needs:
 * - TOA ID#
 * - Actions
 * - Issue count
 * - Shared with list
 */
function Metadata({ }) {
  const { order, bomLink, quoteLink } = useContext<OrderDetailsContextType>(OrderDetailsContext);

  const deliveryString = getDeliveryDateString(order)
  const installString = order.installationDate ? moment(order.installationDate).utc().format('ddd M/D') : null

  return <Card>
    {/* Top Section */}
    <div className="flex items-center pb-3 border-b">
      {/* TODO: TOA ID# */}
      <h1 className="text-xl font-semibold leading-7">{order.name}</h1>
      <div className="ml-auto">
        {/* TODO: implement actions */}
        {/* <IconDropdown /> */}
      </div>
    </div>

    <div className="flex flex-wrap pt-3">
      <div className="grow">
        <div className="grid grid-cols-4 col-span-2 text-sm text-gray-500 font-medium">
          <Property label="Delivery Date" value={deliveryString ?? "--"} />
          <Property label="Install Date" value={installString} />
          <Property label="Material" value={<>
            <a
              className={bomLink ? "text-primary-green hover:text-primary-green-700 cursor-pointer" : "text-gray-500"}
              href={bomLink}
              target="_blank"
            >
              {
                order.files.billOfMaterials.length > 0
                  ? "BOM"
                  : "--"
              }
            </a>
            {" | "}
            <a
              className={quoteLink ? "text-primary-green hover:text-primary-green-700 cursor-pointer" : "text-gray-500"}
              href={quoteLink}
              target="_blank"
            >
              {
                order.quote?.file?.filePath
                  ? "Quote"
                  : "--"
              }
            </a>
          </>} />
          <Property label="Address" value={
            AddressHelper.toString(order.orderAddress)
              ?.split('\n')
              .map((line, i) => <div key={i}>{line}</div>)
          } />
          <Property label="Amount" value={
            order.quote?.value ? Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD'
            }).format(order.quote.value) : "--"

          } />
          <Property label="Contact Info" value={<div>
            <div>{order.contact?.name || <span className="italic">No name</span>}</div>
            <div>{order.contact?.phone || <span className="italic">No phone</span>}</div>
            <div>{order.contact?.email || <span className="italic">No email</span>}</div>
          </div>} />
        </div>
      </div>
      <div className="basis-1/3">
        {/* TODO: shared with */}
        {/* TODO: diff name for # section? */}
        <CardTitle>Document References</CardTitle>
        <div className="grid grid-cols-2 divide-y text-sm text-gray-500 font-medium">
          <Property label="PO #" value={order.poNumber ?? "--"} />
          <Property label="SO #" value={order.soNumber ?? "--"} />
          <Property label="Loan #" value={"--"} />
        </div>
      </div>
    </div>

  </Card>
}

/**
  * Currently placeholder for Attachments section.
  */
function Attachments({ }) {
  return <Card>
    <CardTitle>Attachments</CardTitle>
  </Card>
}

/**
  * Currently placeholder for Photos section.
  */
function Photos({ }) {
  return <Card>
    <CardTitle>Photos</CardTitle>
  </Card>
}

/**
  * Displays a timeline of notes for the order.
  */
function Notes({ }) {
  const { order } = useContext<OrderDetailsContextType>(OrderDetailsContext);
  return <Card>
    <CardTitle>Notes</CardTitle>
    <OrderNotes order={order} />
  </Card>
}

/**
  * Displays a timeline of activity for the order.
  */
function Activity({ }) {
  const { order } = useContext<OrderDetailsContextType>(OrderDetailsContext);
  return <Card>
    <CardTitle>Activity</CardTitle>
    <OrderTimeline hideOrderLink orders={[order]} />
  </Card>
}

/**
  * Handles styling for card titles throughout the page.
  */
function CardTitle({ children }) {
  return <h2 className="text-base font-semibold pb-3">{children}</h2>
}

// TODO: edit functionality for properties? callback prop?
/**
  * Displays a single property in a grid format.
  * Each property takes up 2 columns: 1 for the label, 1 for the value.
  * Handles styling.
  */
function Property({ label, value }) {
  return <div className="grid grid-cols-subgrid col-span-2 py-2">
    <div>{label}</div>
    <div className="font-bold">{value}</div>
  </div>
}
