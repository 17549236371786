import React, { useState, useEffect, useRef } from 'react'
import Modal from '../Modal'
import Dropdown from '../input/Dropdown'
import ComboboxSearchBar from '../ComboboxSearchBar';
import classNames from '../../tools/classNames'
import Button from '../input/Button'
import Input, { RequiredAsterisk } from '../input/Input'
import TimeRangeInput from '../input/TimeRangeInput.tsx';
import UserManager from '../../tools/UserManager'
import {
  XMarkIcon,
  UserPlusIcon,
  PaperClipIcon,
  CheckCircleIcon,
  XCircleIcon,
  PlusCircleIcon,
  MinusCircleIcon
} from '@heroicons/react/20/solid'
import {
  BuildingOffice2Icon,
  CalendarIcon,
  HomeIcon,
  PencilSquareIcon,
  ShoppingCartIcon,
  TrashIcon,
  TruckIcon,
  UserPlusIcon as UserPlusIconOutline
} from '@heroicons/react/24/outline'
import {
  PencilIcon
} from '@heroicons/react/24/solid'
import moment from "moment";
import RadioGroup from '../input/RadioGroup.tsx';
import S3 from 'src/tools/S3/s3.ts';
import DeliveryDateInput, { DeliveryDateTypes } from './common/DeliveryDateInput.tsx';
import useMarketSelect from 'src/hooks/data/markets/useMarketSelect.ts';
import useMarkets from 'src/hooks/data/markets/useMarkets.ts';
import { LucideCalendar, LucideShoppingCart } from 'lucide-react';
import Spinner from '../Spinner';

/**
 * SharedWithBadge component
 */
function SharedWithBadge({
  user,
  removeUser,
  xIcon = true
}) {
  return (
    <div className='flex flex-row items-center gap-2 px-2 py-2 border border-gray-200 rounded-md w-fit bg-gray-50'>
      <div className='text-sm font-medium leading-5 text-center text-gray-700 align-middle'>
        {user.email}
      </div>
      {xIcon && (
        <XMarkIcon
          onClick={removeUser(user.id)}
          className="w-4 h-4 text-gray-700 cursor-pointer hover:text-gray-500"
        />
      )}
    </div>
  )
}

export type OrderModalData = {
  name: string,
  size: string,
  address: {
    line1: string,
    line2: string,
    city: string,
    state: string,
    postalCode: string
  },
  id: string,
  installerId: string,
  marketId: string,
  contact: {
    name: string,
    phone: string,
    email: string
  }
}

type OrderModalProps = {
  showOrderModal: boolean;
  setShowOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: OrderModalData;
  allowMarketSelection?: boolean;
  onSuccess?: () => void;
}

/**
 * Modal component for creating a new order,
 */
export default function OrderModal({
  showOrderModal,
  setShowOrderModal,
  data,
  allowMarketSelection = true,
  onSuccess = () => { }
}: OrderModalProps) {

  const [loading, setLoading] = useState<boolean>(false)

  const [jobData, setJobData] = useState(data)

  const [currentUser, setCurrentUser] = useState<any>({})

  const [poNumber, setPoNumber] = useState('')

  const [distributors, setDistributors] = useState([])
  const [selectedDistributor, setSelectedDistributor] = useState('')
  const [distributorUsers, setDistributorUsers] = useState([])

  const [installerUsers, setInstallerUsers] = useState([])

  const [userSharingSearchValue, setUserSharingSearchValue] = useState('')
  const [userSharingSearchResults, setUserSharingSearchResults] = useState([])

  const [primaryContact, setPrimaryContact] = useState('')
  const [installerOnSiteLead, setInstallerOnSiteLead] = useState('')
  const [sharedUsers, setSharedUsers] = useState([])

  const [quoteRequired, setQuoteRequired] = useState(true)

  const [selectedBOMFile, setSelectedBOMFile] = useState(null)

  // Editing states for name, address, and contact
  // Only one can be edited at a time
  // Set up to look like 3 separate states
  const [editing, setEditing] = useState<"name" | "address" | "contact" | null>()
  const editingAddress = editing === "address"
  const editingName = editing === "name"
  const editingContact = editing === "contact"
  const setEditingAddress = (b: boolean) => setEditing(b ? "address" : null)
  const setEditingName = (b: boolean) => setEditing(b ? "name" : null)
  const setEditingContact = (b: boolean) => setEditing(b ? "contact" : null)

  const [installationDate, setInstallationDate] = useState('');
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState('')
  const [deliveryTimeRangeStart, setDeliveryTimeRangeStart] = useState('')
  const [deliveryTimeRangeEnd, setDeliveryTimeRangeEnd] = useState('')
  const [deliveryDateType, setDeliveryDateType] = useState<DeliveryDateTypes>(DeliveryDateTypes.SPECIFIED);
  const [selectedRelativeDateOption, setSelectedRelativeDateOption] = useState('');

  const [additionalAttachments, setAdditionalAttachments] = useState([])

  const [notes, setNotes] = useState('')

  const BOMInputRef = useRef(null)
  const additionalAttachmentsInputRef = useRef(null)


  const markets = useMarkets(true);
  const marketOptions = [markets?.map(market => ({ label: market.name, value: market._id })) || []]

  // when orderData changes, update the jobName, jobSize, jobAddress, jobId, installerId, and marketId
  // this happens when the user clicks on the "Order" button on the OrdersPage 
  useEffect(() => {
    setJobData(data)
  }, [data])

  // get connected distributors and installer users
  useEffect(() => {
    // get connected distributors
    UserManager.makeAuthenticatedRequest(
      "/api/company/connections-list?status=active",
      "GET"
    )
      .then((response) => {
        setDistributors(response.data.connections.map((connection) => {
          return {
            value: connection.id,
            label: connection.name
          }
        })
        )
      })

    UserManager.getUser()
      .then((user) => {
        setCurrentUser(user)
        UserManager.makeAuthenticatedRequest(
          "/api/company/users?companyId=" + user.userData.company.id,
          "GET"
        )
          .then((response) => {
            setInstallerUsers(response.data.users.map((user) => ({
              value: user._id,
              label: user.email
            })))
          })

        // add user to sharedUsers
        setSharedUsers([
          {
            email: user.userData.email,
            id: user.userData._id
          }
        ])
      })
  }, [])

  // get distributor users when selectedDistributor changes
  useEffect(() => {
    // remove all distributorUsers from sharedUsers
    setSharedUsers(sharedUsers.filter((user) => {
      return !distributorUsers.find((distributorUser) => distributorUser.value === user.id)
    }))

    if (selectedDistributor) {
      UserManager.makeAuthenticatedRequest(
        "/api/company/users?companyId=" + selectedDistributor,
        "GET"
      )
        .then((response) => {
          setDistributorUsers(response.data.users.map((user) => ({
            value: user._id,
            label: user.email
          })))
        })
    }

  }, [selectedDistributor])

  /**
   * Update userSharingSearchResults
   */
  function handleSearchValueChange(value) {
    setUserSharingSearchValue(value)

    // set userSharingSearchResults to all users in distributorUsers + installerUsers that match the search value
    // search results should have an _id and name
    // sort alphabetically by name
    setUserSharingSearchResults(
      distributorUsers.concat(installerUsers).map((user) => ({
        _id: user.value,
        name: user.label
      })).filter((user) =>
        user.name.toLowerCase().includes(value.toLowerCase())
        && !sharedUsers.find((sharedUser) => sharedUser.id === user._id)
      )
        .sort((a, b) => a.name.localeCompare(b.name))
    )
  }

  /**
   * Add user to sharedUsers
   */
  function handleAddSharedUser(user) {
    if (user) {
      // add user selected from search results to sharedUsers
      setSharedUsers([
        ...sharedUsers,
        {
          email: user.name,
          id: user._id
        }
      ])
    }
  }

  /**
   * Close the modal, clear all fields
   */
  function handleCloseModal(value) {
    // clear all fields
    setSelectedDistributor('')
    setDistributorUsers([])
    setUserSharingSearchValue('')
    setUserSharingSearchResults([])
    setPrimaryContact('')
    setQuoteRequired(true)
    setEditingAddress(false)
    setDeliveryTimeRangeStart('')
    setDeliveryTimeRangeEnd('')
    setSharedUsers([
      {
        email: currentUser.userData.email,
        id: currentUser.userData._id
      }
    ])
    setInstallerOnSiteLead('')
    setNotes('')
    setAdditionalAttachments([])
    setSelectedBOMFile(null)
    setPoNumber('')

    setRequestedDeliveryDate('')

    setInstallationDate('')
    setDeliveryDateType(DeliveryDateTypes.SPECIFIED)
    setSelectedRelativeDateOption('')

    setShowOrderModal(value)
  }

  /**
   * Update selectedBOMFile when a file is selected
   */
  function handleOnBOMFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedBOMFile(event.target.files[0]);
    }
  }

  /**
   * Update additionalAttachments when files are selected
   */
  function handleOnAdditionalAttachmentsChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      setAdditionalAttachments([
        ...additionalAttachments,
        ...Array.from(event.target.files)
      ])
    }
  }

  /**
   * Open file dialog to choose BOM file
   */
  function onChooseBOMFile() {
    BOMInputRef.current.click();
  }

  /**
   * Open file dialog to choose additional attachments
   */
  function onChooseAdditionalAttachments() {
    additionalAttachmentsInputRef.current.click();
  }

  /**
   * Remove selected BOM file
   */
  function removeBOMFile() {
    setSelectedBOMFile(null);
    BOMInputRef.current.value = null;
  }

  /**
   * Remove additional attachment at index
   */
  function removeAdditionalAttachment(index) {
    setAdditionalAttachments(additionalAttachments.filter((_, i) => i !== index));
    additionalAttachmentsInputRef.current.value = null;
  }


  /**
   * Build a filepath for the BOM, upload it to S3, and return the filepath
   */
  async function uploadBOMToS3(): Promise<string> {
    // create a filepath for the BOM
    // TODO: if we want to make an order not necessarily tied to a job, we should change the filepath to not include jobData
    // also include bom/ in the filepath to separate it from the attachments, packaging_images, delivery_images, etc.
    var bomFilePath = `bom/${jobData.installerId}/${jobData.marketId}/${jobData.id}/${selectedBOMFile.name}`

    // upload BOM to S3
    await S3.upload(selectedBOMFile, bomFilePath)

    return bomFilePath
  }

  /**
   * Upload additional attachments to S3 and return an array of filepaths
   */
  async function uploadAdditionalAttachmentsToS3(): Promise<string[]> {
    var attachmentFilePaths = []

    const promises = additionalAttachments.map(async (attachment) => {
      // create a filepath for the additional attachment
      // TODO: if we want to make an order not necessarily tied to a job, we should change the filepath to not include jobData
      // also include attachments/ in the filepath to separate it from the BOM, packaging_images, delivery_images, etc.
      var attachmentFilePath = `additional_attachments/${jobData.installerId}/${jobData.marketId}/${jobData.id}/${attachment.name}`

      // upload additional attachment to S3
      await S3.upload(attachment, attachmentFilePath)

      attachmentFilePaths.push(attachmentFilePath)
    });

    await Promise.all(promises);

    return attachmentFilePaths
  }

  /**
   * Upload BOM and additional attachments to S3, then submit the order via /api/orders/create API endpoint
   */
  async function handleSubmitOrder(e) {
    e.preventDefault();
    setLoading(true);

    // upload BOM to S3
    var bomFilePath = await uploadBOMToS3()

    // upload additional attachments to S3
    var additionalAttachmentFilePaths = await uploadAdditionalAttachmentsToS3()

    try {
      var response = await UserManager.makeAuthenticatedRequest(
        '/api/orders/create',
        'POST',
        {
          jobId: jobData.id,
          marketId: jobData.marketId,
          distributorId: selectedDistributor,
          primaryContactId: primaryContact,
          installerOnSiteLeadId: installerOnSiteLead,
          sharedWithIds: sharedUsers.map((user) => user.id),
          jobName: jobData.name,
          jobSize: jobData.size,
          poNumber: poNumber,
          quoteRequired: quoteRequired,
          requestedDeliveryDate: requestedDeliveryDate,
          requestedDeliveryTimeRange: {
            start: deliveryTimeRangeStart,
            end: deliveryTimeRangeEnd
          },
          ...(deliveryDateType === DeliveryDateTypes.RELATIVE && {
            relativeToInstall: {
              amount: selectedRelativeDateOption.split(';')[0].split('-')[0],
              unit: selectedRelativeDateOption.split(';')[0].split('-')[1],
            }
          }),
          ...(installationDate != '' && { installationDate: installationDate }),
          contact: {
            name: jobData.contact.name,
            phone: jobData.contact.phone,
            email: jobData.contact.email
          },
          orderAddress: {
            line1: jobData.address.line1,
            line2: jobData.address.line2,
            city: jobData.address.city,
            state: jobData.address.state,
            postalCode: jobData.address.postalCode
          },
          bomFilePath: bomFilePath,
          additionalAttachmentFilePaths: additionalAttachmentFilePaths,
          notes: notes
        }
      )
    } catch (error) {
      console.error(error)
    }
    //TODO: show success message
    onSuccess?.() // Run if onSuccess is defined
    handleCloseModal(false) // Close modal
    setLoading(false)
  }

  const isValid = {
    submitOrderGroup: [
      selectedDistributor,
      primaryContact,
    ].every(Boolean),

    productRequestGroup: [
      selectedBOMFile,
    ].every(Boolean),

    schedulingDetailsGroup: [
      requestedDeliveryDate,
      deliveryTimeRangeStart,
      deliveryTimeRangeEnd,

      // If relative date, ensure the relative date option is selected
      deliveryDateType === DeliveryDateTypes.RELATIVE ? (installationDate != "" && selectedRelativeDateOption != "") : true
    ].every(Boolean),
  }

  const canSubmit = [
    isValid.submitOrderGroup,
    isValid.productRequestGroup,
    isValid.schedulingDetailsGroup,

    sharedUsers.length,
    jobData.address?.line1,
    jobData.address?.city,
    jobData.address?.state,
    jobData.address?.postalCode,
    jobData.name,
  ].every(Boolean)

  return (
    <Modal
      open={showOrderModal}
      setOpen={handleCloseModal}
      customMaxWidth="990px"
    >
      <form
        onSubmit={handleSubmitOrder}
        className="flex flex-col divide-y min-w-[900px]"
      >
        {/* Modal Header  */}
        <div>
          <div className="text-base font-semibold leading-6 text-left align-middle">
            Purchase Order
          </div>
          <div className='text-sm font-normal leading-5 text-left text-gray-500 align-middle'>
            Request Material or Material Quote
          </div>
        </div>

        {/* Grid to Align Inputs */}
        <div className="grid grid-cols-[auto,1.5fr,auto,1fr] divide-y">
          {/* Order Name, Job, PO#, Market */}
          <Group className="grid grid-cols-subgrid col-span-full justify-items-end items-center gap-3">
            {/* TODO: implement job search */}
            {/* Spacing */}
            <div></div>
            <div></div>
            {/* <div>TODO: job search</div> */}
            <Label>PO#</Label>
            <div className="justify-self-stretch">
              <Input
                placeholder='Optional'
                name="poNumber"
                value={poNumber}
                onChange={(value) => {
                  setPoNumber(value)
                }}
              />
            </div>
            <Label required>Order Name</Label>
            <div
              className="justify-self-stretch"
            >
              <Input
                type="text"
                placeholder='Order Name'
                value={jobData.name}
                onChange={(value) => {
                  setJobData({ ...jobData, name: value })
                }}
                required
              />
            </div>
            {
              allowMarketSelection && <>
                <Label>Market</Label>
                <Dropdown
                  className="justify-self-stretch"
                  justifyLeft
                  options={marketOptions}
                  selectedValue={jobData.marketId}
                  onSelected={(option) => {
                    setJobData({
                      ...jobData,
                      marketId: option.value
                    })
                  }}
                  wide
                />
              </>
            }
          </Group>

          {/* Address & Contact */}
          <Group className="grid grid-cols-subgrid col-span-full justify-items-end gap-3">
            <Label required>Address</Label>
            <div className="grid grid-cols-4 gap-1">
              <div className="col-span-full">
                <Input
                  name="streetAddress"
                  placeholder='Street Address Line 1'
                  required
                  value={jobData.address?.line1 ?? ''}
                  onChange={(value) => {
                    setJobData({
                      ...jobData,
                      address: {
                        ...jobData.address,
                        line1: value
                      }
                    })
                  }}
                />
              </div>
              <div className="col-span-full">
                <Input
                  type="text"
                  placeholder='Street Address Line 2'
                  // label="Street Address Line 2"
                  name="streetAddress2"
                  value={jobData.address?.line2 ?? ''}
                  onChange={(value) => {
                    setJobData({
                      ...jobData,
                      address: {
                        ...jobData.address,
                        line2: value
                      }
                    })
                  }}
                />
              </div>
              <div className="col-span-2">
                <Input
                  required
                  type="text"
                  placeholder='City'
                  name="city"
                  value={jobData.address?.city ?? ''}
                  onChange={(value) => {
                    setJobData({
                      ...jobData,
                      address: {
                        ...jobData.address,
                        city: value
                      }
                    })
                  }}
                />
              </div>
              <div className="col-span-1">
                <Input
                  required
                  type="text"
                  placeholder='State'
                  name="state"
                  value={jobData.address?.state ?? ''}
                  onChange={(value) => {
                    setJobData({
                      ...jobData,
                      address: {
                        ...jobData.address,
                        state: value
                      }
                    })
                  }}
                />
              </div>
              <div className="col-span-1">
                <Input
                  required
                  type="number"
                  hideArrows
                  placeholder='Zip'
                  name="zipCode"
                  value={jobData.address?.postalCode ?? ''}
                  onChange={(value) => {
                    setJobData({
                      ...jobData,
                      address: {
                        ...jobData.address,
                        postalCode: value
                      }
                    })
                  }}
                />
              </div>
            </div>
            <Label>
              Contact Info
              <div className="text-gray-400">(Optional)</div>
            </Label>
            <div className="space-y-1 justify-self-stretch">
              <Input
                type="text"
                name="contact-name"
                placeholder='John Doe'
                value={jobData.contact?.name ?? ""}
                onChange={(value) => {
                  setJobData(old => {
                    if (!old.contact) { old.contact = {} }
                    old.contact.name = value
                    return old;
                  })
                }}
              />
              <Input
                type="tel"
                name="contact-phone"
                placeholder='Phone'
                // TODO: pattern=???
                value={jobData.contact?.phone ?? ""}
                onChange={(value) => {
                  setJobData(old => {
                    if (!old.contact) { old.contact = {} }
                    old.contact.phone = value
                    return old;
                  })
                }}
              />
              <Input
                type="email"
                name="contact-email"
                placeholder='Email'
                value={jobData.contact?.email ?? ""}
                onChange={(value) => {
                  setJobData(old => {
                    if (!old.contact) { old.contact = {} }
                    old.contact.email = value
                    return old;
                  })
                }}
              />
            </div>
          </Group>
        </div>

        {/* TODO: implement more sharing eventually */}
        {/* Other Sharing */}
        {/* <div className='flex flex-col w-full gap-3 py-2'> */}
        {/*   <div className="flex flex-row items-center gap-4"> */}
        {/*     <div className='text-sm font-medium leading-5 text-left align-middle'> */}
        {/*       Shared With: */}
        {/*     </div> */}
        {/*     <div className='relative -mt-1'> */}
        {/*       <ComboboxSearchBar */}
        {/*         placeholder="Search for a user" */}
        {/*         searchValue={userSharingSearchValue} */}
        {/*         searchResults={userSharingSearchResults} */}
        {/*         setSearchValue={handleSearchValueChange} */}
        {/*         onSelectItem={handleAddSharedUser} */}
        {/*       /> */}
        {/*     </div> */}
        {/*   </div> */}
        {/*   <div className='flex flex-row flex-wrap items-center gap-2 overflow-y-auto max-h-[90px]'> */}
        {/*     {sharedUsers.map((user, index) => ( */}
        {/*       <SharedWithBadge */}
        {/*         key={user.id} */}
        {/*         user={user} */}
        {/*         removeUser={(id) => () => { */}
        {/*           setSharedUsers(sharedUsers.filter(user => user.id !== id)) */}
        {/*         }} */}
        {/*         xIcon={user.id !== currentUser?.userData?._id} */}
        {/*       /> */}
        {/*     ))} */}
        {/*   </div> */}
        {/* </div> */}

        {/* Distributor */}
        <Group className="flex items-center gap-2">
          <div>
            <BuildingOffice2Icon className={classNames("w-6 h-6 stroke-2", isValid.submitOrderGroup ? "text-primary-green" : "text-gray-400")} />
          </div>
          <Header required>Submit Order</Header>
          <div className='ml-40 grow'>
            <Dropdown
              label="Distributor"
              justifyLeft
              wide
              className='w-full'
              placeholder="Select a Distributor"
              options={[distributors]}
              onSelected={(selected) => {
                setSelectedDistributor(selected.value)
              }}
              selectedValue={selectedDistributor}
            />
          </div>
          <div className="grow">
            <Dropdown
              disabled={selectedDistributor == ''}
              label="Distributor Rep"
              justifyLeft
              wide
              className='w-full'
              placeholder="Select a Primary Contact"
              options={[distributorUsers]}
              onSelected={(selected) => {
                setPrimaryContact(selected.value)
                // add primary contact to shared users
                if (!sharedUsers.find((user) => user.id === selected.value)) {
                  setSharedUsers([
                    ...sharedUsers,
                    {
                      email: selected.label,
                      id: selected.value
                    }
                  ])
                }
              }}
              selectedValue={primaryContact}
            />
          </div>
        </Group>

        {/* Product Request Details */}
        <Group>
          <div className="flex gap-2">
            <div>
              <LucideShoppingCart className={classNames("w-6 h-6 stroke-2", isValid.productRequestGroup ? "text-primary-green" : "text-gray-400")} />
            </div>
            <div className="flex flex-col divide-y justify-between grow">
              <Header required className="pb-2">Product Request Details</Header>
              <div className="flex justify-between items-center py-2">
                {selectedBOMFile
                  ? (
                    <div className="flex items-center gap-8">
                      <div className="flex items-center gap-2">
                        <CheckCircleIcon className="w-7 h-7 text-primary-green" />
                        <div className='text-sm font-bold leading-5 text-gray-500'>
                          BOM
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className='overflow-hidden text-sm font-normal leading-5 text-left text-gray-500 align-middle text-ellipsis text-nowrap max-w-72'>
                          {selectedBOMFile.name}
                        </div>
                        <div
                          className={classNames(
                            "text-sm leading-5 font-semibold align-middle text-left flex justify-center cursor-pointer",
                            "text-primary-green hover:text-primary-green-400"
                          )}
                          onClick={() => {
                            window.open(URL.createObjectURL(selectedBOMFile))
                          }}
                        >
                          View
                        </div>
                      </div>
                      <div className='flex justify-end pr-3'>
                        <TrashIcon
                          className="w-5 h-5 text-primary-rose cursor-pointer hover:text-primary-rose-700"
                          onClick={() => {
                            removeBOMFile()
                          }}
                        />
                      </div>
                    </div>
                  )
                  : (
                    <div className='flex flex-row items-center'>
                      <Button
                        onClick={() => {
                          onChooseBOMFile()
                        }}
                        variant='primary'
                      >
                        Upload Bill of Materials
                      </Button>
                    </div>
                  )
                }
                <div className='ml-10 flex flex-row items-center gap-3'>
                  <div className="text-sm font-medium leading-5 text-left align-middle">
                    Quote Required
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="w-4 h-4 border-gray-300 rounded text-primary-green focus:ring-primary-green"
                      checked={quoteRequired}
                      onChange={() => setQuoteRequired(!quoteRequired)}
                    />
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  'flex flex-col items-stretch border-gray-300 pt-2',
                  additionalAttachments.length > 0 ? 'pb-1' : ''
                )}
              >
                <div className='flex flex-row items-center justify-between gap-2'>
                  <div className='flex flex-row items-center gap-2'>
                    <div className='text-sm font-bold leading-5 text-gray-500'>
                      Additional Attachments{" "}
                      <span className="text-gray-400 font-medium">(Optional)</span>
                    </div>
                  </div>
                  <div className='pr-2'>
                    {/* TODO: move to middle */}
                    <PlusCircleIcon
                      className="cursor-pointer w-7 h-7 text-primary-green hover:text-primary-green-400"
                      onClick={() => {
                        onChooseAdditionalAttachments()
                      }}
                    />
                  </div>
                </div>
                <div className='flex flex-col w-full gap-1 pl-6'>
                  {additionalAttachments.map((attachment, index) => (
                    <div
                      key={index}
                      className={classNames(
                        'flex flex-row items-center justify-between w-full gap-2 px-3 py-4',
                        index > 0 && 'border-t border-gray-300'
                      )}
                    >
                      <div className='flex flex-row items-center justify-start gap-2'>
                        <div className='overflow-hidden text-sm font-medium leading-5 text-left text-gray-500 align-middle text-ellipsis max-w-80 text-nowrap'>
                          {attachment.name}
                        </div>
                        <div
                          className='text-sm font-semibold leading-5 text-left align-middle cursor-pointer hover:text-primary-green-400 text-primary-green'
                          onClick={() => {
                            window.open(URL.createObjectURL(attachment))
                          }}
                        >
                          View
                        </div>
                      </div>
                      <div>
                        <TrashIcon
                          className="w-5 h-5 text-primary-rose cursor-pointer hover:text-primary-rose-700"
                          onClick={() => {
                            removeAdditionalAttachment(index)
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Group>

        {/* Scheduling Details */}
        <Group>
          <div className="flex gap-2">
            <div>
              <LucideCalendar className={classNames("w-6 h-6 stroke-2", isValid.schedulingDetailsGroup ? "text-primary-green" : "text-gray-400")} />
            </div>
            {/* TODO: required */}
            <div className="flex flex-col divide-y grow">
              <Header required className="pb-2">Scheduling Details</Header>
              <div className="flex flex-col gap-3 text-sm font-medium items-stretch px-40 py-2">
                <div className="flex gap-2 items-center">
                  <HomeIcon className="w-7 h-7 text-gray-700 stroke-2" />
                  <p>Install Date / Time</p>
                  <div className="ml-auto">
                    <Input
                      type="date"
                      name="installationDate"
                      value={installationDate}
                      onChange={(value) => {
                        setInstallationDate(value ? moment(value).format("YYYY-MM-DD") : value)
                        if (value == "") {
                          setDeliveryDateType(DeliveryDateTypes.SPECIFIED)
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="border-t border-gray-300"></div>
                <DeliveryDateInput
                  requestedDeliveryDate={requestedDeliveryDate}
                  deliveryTimeRangeStart={deliveryTimeRangeStart}
                  deliveryTimeRangeEnd={deliveryTimeRangeEnd}
                  deliveryDateType={deliveryDateType}
                  selectedRelativeDateOption={selectedRelativeDateOption}
                  installationDate={installationDate}
                  onRequestedDeliveryDateChange={setRequestedDeliveryDate}
                  onDeliveryTimeRangeStartChange={setDeliveryTimeRangeStart}
                  onDeliveryTimeRangeEndChange={setDeliveryTimeRangeEnd}
                  onDeliveryDateTypeChange={setDeliveryDateType}
                  onSelectedRelativeDateOptionChange={setSelectedRelativeDateOption}
                />
                <div className="flex gap-2 items-center">
                  <UserPlusIconOutline className="w-7 h-7 text-gray-700 stroke-2" />
                  <p>Installer Onsite Lead</p>
                  <div className="ml-auto">
                    <Dropdown
                      justifyLeft
                      wide
                      placeholder="Select an Onsite Lead"
                      options={[installerUsers]}
                      onSelected={(selected) => {
                        setInstallerOnSiteLead(selected.value)
                        // add installer onsite lead to shared users
                        if (!sharedUsers.find((user) => user.id === selected.value)) {
                          setSharedUsers([
                            ...sharedUsers,
                            {
                              email: selected.label,
                              id: selected.value
                            }
                          ])
                        }
                      }}
                      selectedValue={installerOnSiteLead}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Group>

        {/* Notes */}
        <Group className="flex gap-3">
          <Label><span className="text-gray-500 font:semibold">Notes:</span></Label>
          <div className="grow">
            <Input
              type="textarea"
              name="notes"
              placeholder='Enter any notes here...'
              value={notes}
              onChange={(value) => setNotes(value)}
            />
          </div>
        </Group>

        {/* Actions */}
        <Group>
          <div className="flex justify-end gap-2">
            {
              loading ? <div className="flex items-center pr-7">
                <Spinner size={20} />
              </div> :
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!canSubmit}
                >
                  Submit Order
                </Button>
            }
            <Button
              variant="secondary"
              onClick={() => handleCloseModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Group>

        {/* hidden file input element for BOM */}
        <input
          ref={BOMInputRef}
          type="file"
          className="hidden"
          onChange={handleOnBOMFileChange}
        />

        {/* hidden file input element for additional attachments */}
        <input
          ref={additionalAttachmentsInputRef}
          type="file"
          className="hidden"
          onChange={handleOnAdditionalAttachmentsChange}
          multiple
        />

      </form>
    </Modal >
  )
}

type LabelProps = {
  children: React.ReactNode,
  required?: boolean
}

/**
  * Simple label to display an input's label.
  * Handles styling.
  * If `required`, displays an asterisk
  */
function Label({ children, required = false }: LabelProps) {
  return <p className="text-sm font-medium">{children}{required && <>{" "}<RequiredAsterisk /></>}</p>
}

type HeaderProps = {
  children: React.ReactNode,
  className?: string,
  required?: boolean
}

/**
  * Simple group header for the form.
  * Handles styling.
  * If `required`, displays an asterisk
  */
function Header({ children, className = "", required = false }: HeaderProps) {
  return <p className={classNames("text-base font-semibold", className)}>
    {children}
    {required && <>{" "}<RequiredAsterisk /></>}
  </p>
}

type GroupProps = {
  children: React.ReactNode,
  className?: string
}

/**
  * Simple group for sections of the form.
  * Handles padding.
  */
function Group({ children, className = "" }: GroupProps) {
  return <div className={classNames("py-3 px-6", className)}>
    {children}
  </div>
}
