import UserManager from './UserManager';
import { getLocations } from './locationManagement';

// Table columns for the InputTable
export async function tableColumns() {

    const user = await UserManager.getUser(true);

    var locations;
    if (user.userData.company.type === 'distributor') {
        locations = await getLocations();
    }

    const roleOptions = [
        { value: 'admin', label: 'Admin' },
        { value: 'member', label: 'Member' },
        ...(
            user?.userData?.company?.type === 'installer'
                ? [{ value: 'install-crew', label: 'Install Crew' }]
                : user?.userData?.company?.type === 'distributor'
                    ? [
                        { value: 'operations', label: 'Operations' },
                        { value: 'warehouse', label: 'Warehouse' },
                        { value: 'driver', label: 'Driver' },
                    ]
                    : []
        )
    ]

    const columns = [
        {
            Header: "Name",
            accessor: "name",
            placeholder: "John Doe",
            required: false,
            primary: true,
            columnWidth: 3,
            editable: false,
            editType: null,
            validate: (value) => { return true },
        },
        {
            Header: "Email",
            accessor: "email",
            placeholder: "example@email.com",
            required: true,
            primary: true,
            columnWidth: 5,
            editable: false,
            editType: null,
            validate: (value) => { return true },
        },
        {
            Header: "Status",
            accessor: "status",
            placeholder: "",
            required: true,
            primary: true,
            columnWidth: 2,
            editable: false,
            editType: null,
            validate: (value) => { return true },
        },
        {
            Header: "Role",
            accessor: "role",
            placeholder: "Role",
            required: true,
            primary: true,
            columnWidth: 2,
            editable: true,
            editType: 'dropdown',
            options: [
                roleOptions
            ],
            validate: (value) => { return true },
        },
        ...(user.userData.company.type === 'distributor' ?
            [{
                Header: "Location",
                accessor: "location",
                placeholder: "Location",
                required: false,
                primary: true,
                columnWidth: 3,
                editable: true,
                editType: 'dropdown',
                options: [
                    locations.map(location => {
                        return {
                            value: location._id,
                            label: location.name
                        }
                    })
                ],
                validate: (value) => { return true },
            }]
            : []
        )
    ]

    return columns;
}


/*
* Helper function to structure row data for the InputTable
*/
export function structureRowData({ id, name, email, status, role, location = null, rowStatus, menuOptions }) {
    return {
        id: id,
        name: {
            value: name,
            className: ''
        },
        email: {
            value: email,
            className: ''
        },
        status: {
            value: status,
            // add a green background to the status if it's active, yellow if it's pending
            className: status === 'Active'
                ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-green-700 ring-1 ring-inset ring-green-200'
                : status === 'Pending Invite'
                    ? 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-yellow-700 ring-1 ring-inset ring-yellow-200'
                    : ''
        },
        role: {
            value: role,
            className: ''
        },
        ...(location && {
            location: {
                value: location,
                className: ''
            }
        }),
        rowStatus: rowStatus,
        menuOptions: menuOptions
    }
}

/**
 * Sort users by status, Active first, then Pending Invite, then alphabetically by name
 * @param {array} users - an array of users
 * 
 * @returns - a sorted array of users 
 */
export function sortUsers(users) {
    // sort first by status, Active first, then Pending Invite
    // then inside each status, sort names alphabetically
    return users.sort((a, b) => {
        if (a.status.value === 'Active' && b.status.value === 'Pending Invite') {
            return -1;
        }
        if (a.status.value === 'Pending Invite' && b.status.value === 'Active') {
            return 1;
        }
        if (a.status.value === 'Active' && b.status.value === 'Active') {
            return a.name.value.localeCompare(b.name.value);
        }
        if (a.status.value === 'Pending Invite' && b.status.value === 'Pending Invite') {
            return a.name.value.localeCompare(b.name.value);
        }
    });
}


/**
* send invites to the emails in the emailsToAdd array
* @param {array} users - the users in the table
* @param {function} setUsers - function to set the users in the table
* @param {array} emailsToAdd - the emails to send invites to
* @param {function} setEmailsToAdd - function to set the emails to send invites to
* @param {string} role - the role to assign to the new users
* @param {function} setError - function to set an error message
*/
export function sendInvites(users, setUsers, emailsToAdd, setEmailsToAdd, role, setError) {
    // add emailsToAdd (from email input) to users (in the table)
    let newUsers = [...users]

    // Create an array of promises
    const promises = emailsToAdd.map(email => {
        // if email is already in the table, don't add it again
        if (users.find(user => user.email === email)) {
            return Promise.resolve(); // return a resolved promise
        }

        // Return the promise from makeAuthenticatedRequest
        return UserManager.makeAuthenticatedRequest("/api/invite/create", "POST", {
            email: email,
            role: role,
        }).then((response) => {
            if (response.data.status === "ok") {
                newUsers.push(structureRowData({
                    id: email,
                    name: '--',
                    email: email,
                    status: 'Pending Invite',
                    role: role.charAt(0).toUpperCase() + role.slice(1),
                    location: 'None',
                    rowStatus: 'readonly',
                    menuOptions: [
                        { label: 'Delete', value: 'delete', onSelected: () => deleteRow({email: {value: email}}, setError) },
                        { label: 'Edit', value: 'edit' }
                    ]
                }))
            }
            else if (response.data.status === "error") {
                console.log("Error: ", response.data.error);
            }
        }).catch((error) => {
            console.log("Caught error: ", error);
        });
    });

    // Wait for all promises to resolve
    Promise.all(promises).then(() => {
        newUsers = sortUsers(newUsers)
        setUsers(newUsers);
        setEmailsToAdd([]);
    });
}



/**
 * delete a row from the table
 * @param {object} data - the row data to delete
 * @param {function} setError - function to set an error message
 */
export function deleteRow(data, setError) {
    // try deleting the invite
    UserManager.makeAuthenticatedRequest("/api/invite/delete", "DELETE", {
        email: data.email.value,
    }).then((response) => {
        if (response.data?.status === "ok") {
            setError("");
        }
        else if (response.data?.status === "error") {
            console.log("Error: ", response.data.error);

            // the invite might have already been accepted, so try to deactivate the user instead
            UserManager.makeAuthenticatedRequest("/api/user/archive", "PATCH", {
                email: data.email.value,
            }).then((response) => {
                if (response.data.status === "ok") {
                    setError("");
                }
                else if (response.data.status === "error") {
                    console.log("Error: ", response.data.error);
                    setError("Error deleting invite");
                }
            });
        }
    });
}


/**
 * Archive a user
 * @param {object} data - the row data containing the email of the user to archive
 * @param {function} setError - function to set an error message
 * @param {function} setUsers - function to set the users in the table
 */
export function archiveUser(data, setError, setUsers) {
    var removeUser = true;
    const promise = UserManager.makeAuthenticatedRequest("/api/user/archive", "PATCH", {
        email: data.email.value,
    }).then((response) => {
        if (response.data.status === "ok") {
            setError("");
        }
        else if (response.data.status === "error") {
            console.log("Error: ", response.data.error);
            setError(response.data.error);
            removeUser = false;
        }
    });

    // when the promise resolves, remove the user from the table
    promise.then(() => {
        // if response status is error, don't remove the user
        if (!removeUser) {
            return;
        }
        // remove the user from the table
        setUsers(prevUsers => prevUsers.filter(user => user.email.value !== data.email.value))
    })
}


/**
 * Update a user's role
 * @param {object} data - the row data containing the email of the user to update
 * @param {function} setError - function to set an error message
 */
export async function updateRole(data, setError) {
    let result = null;

    const roleMap = {
        'admin': 'Admin',
        'member': 'Member',
        'operations': 'Operations',
        'warehouse': 'Warehouse',
        'driver': 'Driver',
        'install-crew': 'Install Crew'
    }

    try {
        // if status is pending, change the role in the invite in the company object
        if (data.status.value === 'Pending Invite') {
            const response = await UserManager.makeAuthenticatedRequest("/api/invite/update-role", "POST", {
                email: data.email.value,
                // find the role in roleMap whose key is the value of data.role
                role: Object.keys(roleMap).find(key => roleMap[key] === data.role.value),
            });
            if (response.data?.status === "ok") {
                setError("");
                return {
                    id: response.data.invite?.email,
                    newValue: roleMap[response.data.invite?.role],
                    column: 'role'
                }
            }
            else if (response.data?.status === "error") {
                console.log("Error: ", response.data.error);
                setError(response.data.error);
            }

        }
        // if status is not pending, change the role in the user object
        else {
            console.log("data.role", data.role);
            const response = await UserManager.makeAuthenticatedRequest("/api/user/update-role", "POST", {
                email: data.email.value,
                role: Object.keys(roleMap).find(key => roleMap[key] === data.role.value),
            });
            if (response.data?.status === "ok") {
                setError("");
                return {
                    id: response.data.user?._id,
                    newValue: roleMap[response.data.user?.company?.role],
                    column: 'role'
                }
            }
            else if (response.data?.status === "error") {
                console.log("Error: ", response.data.error);
                setError(response.data.error);
            }
        }
    }
    catch (error) {
        console.error(error);
        setError("Error updating role");
    }
}


/**
 * Update a user's location
 * @param {object} data - the row data containing the email of the user to update
 * @param {function} setError - function to set an error message
 */
export async function updateLocation(data, setError) {
    let result = null;

    const locations = await getLocations();

    try {
        // if status is pending, change the role in the invite in the company object
        if (data.status.value === 'Pending Invite') {
            const response = await UserManager.makeAuthenticatedRequest("/api/invite/update-location", "POST", {
                userEmail: data.email.value,
                locationId: locations.find(location => location.name === data.location.value)?._id,
            });
            if (response.data?.status === "ok") {
                setError("");
                return {
                    id: response.data.invite?.email,
                    newValue: response.data.location?.name,
                    column: 'location'
                }
            }
            else if (response.data?.status === "error") {
                console.error("Error: ", response.data.error);
                return {
                    id: data.email.value,
                    newValue: data.location.value,
                    column: 'location'
                }
            }

        }
        // if status is not pending, change the role in the user object
        else {
            const response = await UserManager.makeAuthenticatedRequest("/api/user/update-location", "POST", {
                userEmail: data.email.value,
                locationId: locations.find(location => location.name === data.location.value)?._id,
            });
            if (response.data?.status === "ok") {
                setError("");
                return {
                    id: response.data.user?._id,
                    newValue: response.data.location?.name,
                    column: 'location'
                }
            }
            else if (response.data?.status === "error") {
                console.error("Error: ", response.data.error);
                return {
                    id: response.data.user?._id,
                    newValue: data.location.value,
                    column: 'location'
                }
            }
        }
    }
    catch (error) {
        console.error(error);
        setError("Error updating location");
    }
}