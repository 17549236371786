import { PaperClipIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import Button from 'src/components/input/Button';
import FileUpload from 'src/components/input/FileUpload.tsx';
import Input from 'src/components/input/Input';
import Modal, { ModalFooter } from 'src/components/Modal';
import Spinner from 'src/components/Spinner';
import SwitchCase from 'src/components/utils/SwitchCase.tsx';
import useConnections from 'src/hooks/data/connections/useConnections.ts';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import useLocations from 'src/hooks/data/locations/useLocations.ts';
import useMarkets from 'src/hooks/data/markets/useMarkets.ts';
import { AgreementStatus, Order, TimestampTypes } from 'src/hooks/data/orders/useOrders.ts';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import useUsersByIds from 'src/hooks/data/users/useUsersByIds.ts';
import S3 from 'src/tools/S3/s3.ts';
import UserManager from 'src/tools/UserManager';
import StringHelper from 'src/utils/stringHelper.ts';
import AgreementInputRows from '../common/AgreementInputRows.tsx';
import { MinorBadge } from '../OrderRowItem.tsx';
import OrderTimeline from '../OrderTimeline.tsx';

// TODO: parent prop type for all order modals?
type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
}

/**
 * Component for approving/reviewing an order.
 * Delivery date, material, and amount can be adjusted or confirmed.
 * Only the distributor can adjust the amount.
 * A new BOM/Quote can be uploaded.
 * Displays a timeline of changes.
 */
export default function ApproveOrderModal({
  order: order_prop, open, setOpen
}: Props) {


  // --- States --- //

  const [order, setOrder] = useState<Order>(structuredClone(order_prop));
  const [additionalNote, setAdditonalNote] = useState<string>("");
  const [materialNote, setMaterialNote] = useState<string>("");

  const [newBOM, setNewBOM] = useState<File | null>(null);
  const [newQuote, setNewQuote] = useState<File | null>(null);

  const [requestBOM, setRequestBOM] = useState<boolean>(false);
  const [requestQuote, setRequestQuote] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  // --- Hooks --- //

  const user = useCurrentUser();
  const companyType = user?.company?.type;

  const connections = useConnections(); // TODO: only get specific one? pass from parent?
  let connectionId = null;
  switch (companyType) {
    case "installer":
      connectionId = order.distributorId;
      break;
    case "distributor":
      connectionId = order.installerId;
      break;
  }
  const connectionName = connections
    ?.find(c => c.id === connectionId)
    ?.name;

  const markets = useMarkets()
  const marketName = markets?.find(m => m._id === order.marketId)?.name;

  const locations = useLocations();
  const locationName = locations?.find(l => l._id === order.locationId)?.name;

  const usersMap = useUsersByIds([order.primaryContactId, order.installerOnSiteLeadId, ...order.sharedWithIds]);
  const users = usersMap ? Object.values(usersMap) : [];

  // --- Consts --- //

  // BOM
  const bomFilePath = order.files.billOfMaterials.at(-1)?.filePath;
  const bomName = bomFilePath ? bomFilePath.split("/").pop() : null;
  const bomLinkArr = useS3URLs(bomFilePath ? [bomFilePath] : []);
  const bomLink = bomLinkArr?.length ? bomLinkArr[0].getObjectSignedUrl : null;

  // Quote
  const quoteFilePath = order.quote?.file?.filePath;
  const quoteName = quoteFilePath ? quoteFilePath.split("/").pop() : null;
  const quoteLinkArr = useS3URLs(quoteFilePath ? [quoteFilePath] : []);
  const quoteLink = quoteLinkArr?.length ? quoteLinkArr[0].getObjectSignedUrl : null;

  // Activity timeline filters
  const timelineTimestamps = [
    TimestampTypes.AGREEMENT_CHANGED,
    TimestampTypes.NOTE_ADDED,
  ]

  // Submit conditions
  const canSubmit = [
    order.quote?.agreements.deliveryDate[companyType] != AgreementStatus.PENDING,
    order.quote?.agreements.material[companyType] != AgreementStatus.PENDING,
    order.quote?.agreements.amount[companyType] != AgreementStatus.PENDING,
  ].every(Boolean);

  /**
   * Handles uploading the file to S3 as a Quote.
   * Returns the S3 filepath. 
   * Returns null if it fails.
   */
  async function uploadQuote(file: File): Promise<string> {
    // TODO: improve file path? can do later
    let quoteFilePath = `${order.installerId}/${order.marketId}/${order._id}/quote/${file.name}`;

    await S3.upload(file, quoteFilePath);

    return quoteFilePath;
  }

  /**
   * Handles uploading the file to S3 as a BOM.
   * Returns the S3 filepath. 
   * Returns null if it fails.
   */
  async function uploadBOM(file: File): Promise<string> {
    // TODO: improve file path? can do later
    let bomFilePath = `bom/${order.installerId}/${order.marketId}/${order._id}/${file.name}`

    await S3.upload(file, bomFilePath);

    return bomFilePath;
  }

  /**
   * Submit order agreement changes.
   * Called from form and prevents event default.
   */
  async function handleSubmit(event) {
    event.preventDefault(); // Don't refresh page
    setLoading(true); // Start spinner


    // Upload BOM/quote and get resulting S3 filepath
    let newFile = companyType == "installer" ? newBOM : newQuote;
    if (newFile) {
      var newFilePath = companyType == "installer" ? await uploadBOM(newFile) : await uploadQuote(newFile);
    }

    // TODO: show error message
    if (!newFilePath && newFile) return;

    let body = {
      orderId: order._id,

      deliveryDateAgreement: order.quote.agreements.deliveryDate[companyType],
      deliveryDate: order.quote.agreements.deliveryDate[companyType] === AgreementStatus.REJECTED ? order.requestedDelivery : undefined,

      materialAgreement: order.quote.agreements.material[companyType],
      materialNote: order.quote.agreements.material[companyType] === AgreementStatus.REJECTED ? materialNote : undefined,

      amountAgreement: order.quote.agreements.amount[companyType],
      amount: companyType === "distributor" && order.quote.agreements.amount[companyType] === AgreementStatus.REJECTED ? order.quote.value : undefined,

      additionalNote: additionalNote,

      newFilePath: newFilePath,

      // TODO: requestBOM, requestQuote
    }

    try {
      var res = await UserManager.makeAuthenticatedRequest(`/api/orders/approve-order`, "POST", body)
    } catch (err) {
      // TODO: error message
      console.error(err);
    }

    setOpen(false); // Close modal
    setLoading(false)
  }

  return (
    <Modal open={open} setOpen={setOpen} wide>
      <form onSubmit={handleSubmit}>
        <div className="flex divide-x max-h-[80vh]">

          {/* Order */}
          <div className="flex flex-col divide-y pr-6 overflow-y-scroll">
            {/* Header */}
            <div className="flex items-center justify-between pb-3">
              <div className="flex flex-col">
                <div className="text-base font-semibold">Approve Order</div>
                <div className="text-sm font-normal text-gray-500">Approve or adjust parts of the order</div>
              </div>
              <div className="grid grid-cols-2 gap-2 whitespace-nowrap">
                <div className="text-sm font-medium">PO #:</div>
                <div className="text-sm font-normal text-gray-400">{order.poNumber ?? "--"}</div>
                <div className="text-sm font-medium">SO #:</div>
                <div className="text-sm font-normal text-gray-400">{order.soNumber ?? "--"}</div>
                {/* TODO: TOA ID when we make it */}
              </div>
            </div>

            <div>
              {/* Sharing */}
              <div className="flex items-start gap-6 py-4">
                <div className="grid grid-cols-[auto,1fr] gap-2 grow">
                  <div className="text-sm font-medium whitespace-nowrap">Distributor:</div>
                  {/* Connection Badge */}
                  <div>
                    <MinorBadge>
                      {connections ? (connectionName ?? <span className="italic">No Connection</span>) : <Loading />}
                    </MinorBadge>
                  </div>
                  {/* Market or Location Badge */}
                  <SwitchCase test={companyType}>
                    {/* Market */}
                    <div data-case="installer" className="contents">
                      <div className="text-sm font-medium whitespace-nowrap">Market:</div>
                      <div>
                        <MinorBadge>
                          {markets ? (marketName ?? <span className="italic">No Market</span>) : <Loading />}
                        </MinorBadge>
                      </div>
                    </div>
                    {/* Location */}
                    <div data-case="distributor" className="contents">
                      <div className="text-sm font-medium whitespace-nowrap">Location:</div>
                      <div>
                        <MinorBadge>
                          {locations ? (locationName ?? <span className="italic">No location</span>) : <Loading />}
                        </MinorBadge>
                      </div>
                    </div>
                  </SwitchCase>
                </div>
                {/* Shared With Section */}
                <div className="flex flex-col gap-2 grow">
                  <div className="text-sm font-medium">Shared With</div>
                  <div className="flex flex-wrap gap-2">
                    {users?.map(user => (
                      <MinorBadge key={user._id}>
                        {user.email}
                      </MinorBadge>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Order Details */}
            <div className="flex justify-between py-4">
              {/* Name */}
              <div className="flex flex-col">
                <div className="text-sm font-medium">Order Name</div>
                <div className="text-sm font-normal text-gray-500">{order.name ?? <span className="italic">No Name</span>}</div>
              </div>
              {/* Address */}
              <div className="flex flex-col">
                <div className="text-sm font-medium">Address</div>
                <div className="text-sm font-normal text-gray-500">
                  <div>{order.orderAddress.line1}</div>
                  {order.orderAddress.line2 && <div>{order.orderAddress.line2}</div>}
                  <div>{order.orderAddress.city}, {order.orderAddress.state} {order.orderAddress.postalCode}</div>
                </div>
              </div>
              {/* Contact */}
              <div className="flex flex-col">
                <div className="text-sm font-medium">Contact Info</div>
                <div className="text-sm font-normal text-gray-500">
                  <div>{order.contact?.name}</div>
                  <div>{order.contact?.phone}</div>
                  <div>{order.contact?.email}</div>
                </div>
              </div>
            </div>

            {/* Existing Files */}
            <div className="py-3 grid grid-cols-2 gap-2">
              {!newBOM && <FileDisplay
                filename={bomName ?? "--"}
                link={bomLink}
                label="BOM"
              />}
              <SwitchCase test={companyType}>
                <div data-case="installer" className={newBOM ? "col-span-2" : ""}>
                  <FileUpload
                    uploadText="Upload New BOM"
                    uploadedText="New BOM"
                    buttonVariant="gray"
                    onFileChange={setNewBOM}
                  />
                </div>
                <div data-case="distributor">
                  {/* TODO: implement */}
                  {/* <Input */}
                  {/*   type="checkbox" */}
                  {/*   label="Request BOM" */}
                  {/*   name="requestBOM" */}
                  {/*   value={requestBOM} */}
                  {/*   onChange={setRequestBOM} */}
                  {/* /> */}
                </div>
              </SwitchCase>
            </div>
            <div className="py-3 grid grid-cols-2 gap-2">
              {!newQuote && <FileDisplay
                filename={quoteName ?? "--"}
                link={quoteLink}
                label="Quote"
              />}
              <SwitchCase test={companyType}>
                <div data-case="distributor" className={newQuote ? "col-span-2" : ""}>
                  <FileUpload
                    uploadText="Upload New Quote"
                    uploadedText="New Quote"
                    buttonVariant="gray"
                    onFileChange={setNewQuote}
                  />
                </div>
                <div data-case="installer" className="flex items-center justify-center">
                  {/* TODO: implement */}
                  {/* <Input */}
                  {/*   type="checkbox" */}
                  {/*   label="Request Quote" */}
                  {/*   name="requestQuote" */}
                  {/*   value={requestQuote} */}
                  {/*   onChange={setRequestQuote} */}
                  {/* /> */}
                </div>
              </SwitchCase>
            </div>


            {/* Agreement Changes */}
            <AgreementInputRows
              order={order}
              originalOrder={order_prop}
              onChange={setOrder}
              materialNote={materialNote}
              setMaterialNote={setMaterialNote}
            />

            {/* Add Note */}
            < div className="py-2">
              <Input
                label="Add Additional Note:"
                placeholder='Add an additional note here...'
                type="textarea"
                value={additionalNote}
                onChange={setAdditonalNote}
              />
            </div>
          </div>

          {/* Activity */}
          <div className="px-6 flex flex-col">
            <div className="text-sm font-medium pb-3">Adjustment Timeline</div>
            <div className="overflow-y-scroll grow">
              {/* TODO: original order state? agreement timestamp is changing */}
              <OrderTimeline
                orders={[order]}
                timestampFilter={timelineTimestamps}
                hideOrderLink
              />
            </div>
          </div>

        </div>

        {/* Footer */}
        <ModalFooter>
          <div className="flex justify-end gap-2">
            {
              !loading
                ? <Button
                  variant="primary"
                  disabled={!canSubmit}
                  type="submit"
                >
                  Submit
                </Button>
                : <div className="flex items-center pr-7">
                  <Spinner size={20} />
                </div>
            }
            <Button
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  )
}

/**
 * Component to display a file reference.
 * Displays a paper clip icon, file name, and file link
 */
function FileDisplay({ filename, label, link }) {
  return <div className="flex items-center gap-3">
    <div className="flex items-center gap-2">
      <div className="flex items-center justify-center w-8 h-8 bg-gray-700 rounded-full">
        <PaperClipIcon className="w-5 h-5 text-white" />
      </div>
      <p className="text-sm font-semibold text-gray-600">
        {label}
      </p>
    </div>
    <div className="text-sm font-medium text-gray-500">
      {StringHelper.truncate(filename, 16)}
      {" "}
      <a
        className="font-semibold cursor-pointer text-primary-green hover:text-primary-green-700"
        href={link}
        target="_blank"
      >
        View
      </a>
    </div>
  </div>

}

/**
  * Super simple loading component.
  * Pulses "Loading"
  */
function Loading({ }) {
  return <span className="animate-pulse">Loading</span>
}
