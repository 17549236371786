import { CheckIcon, HomeIcon, PaperClipIcon, TrashIcon, TruckIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react'
import Modal from 'src/components/Modal'
import Button from 'src/components/input/Button';
import Input from 'src/components/input/Input';
import { AgreementStatus, Order } from 'src/hooks/data/orders/useOrders.ts';
import { AgreementBubble, AgreementTypes, getAgreementStatus } from '../OrderAgreementIcons.tsx';
import moment from 'moment';
import useUsersByIds from 'src/hooks/data/users/useUsersByIds.ts';
import useConnections from 'src/hooks/data/connections/useConnections.ts';
import UserManager from 'src/tools/UserManager.js';
import AgreementInputRows from '../common/AgreementInputRows.tsx';
import S3 from 'src/tools/S3/s3.ts';
import StringHelper from 'src/utils/stringHelper.ts';
import OrderNotes from '../common/OrderNotes.tsx';
import Spinner from 'src/components/Spinner';

// TODO: parent prop type for all order modals?
type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
}

/*
 *  _   _  ____ _______ _____ _____ ______ 
 * | \ | |/ __ \__   __|_   _/ ____|  ____|
 * |  \| | |  | | | |    | || |    | |__   
 * | . ` | |  | | | |    | || |    |  __|  
 * | |\  | |__| | | |   _| || |____| |____ 
 * |_| \_|\____/  |_|  |_____\_____|______|
 *
 * TODO: what is left
 *
 * - validate quote endpoint input
 * - Shared with add/subtract
 *
 * BUG: layout issues on small screens
 */

/**
 * Modal for the distributor to add a quote to an order.
 * A form that takes a few inputs and a file upload.
 * - SO Number
 * - Quote Amount
 * - Quote File
 * - Delivery Date Agreement
 * - Material Agreement
 *
 * Down the road:
 * - Shared With adjustments
 * - Delivery Date/Material adjustments
 */
export default function AddQuoteModal({ order: order_prop, open, setOpen: setOpen_prop }: Props) {

  // ------------------------------------ //
  // --- States, Hooks, Memos, & Refs --- //
  // ------------------------------------ //

  const [loading, setLoading] = useState<boolean>(false);

  const [order, setOrder] = useState(structuredClone(order_prop));
  const [materialNote, setMaterialNote] = useState<string>(""); // Note for material adjustments
  const [quoteFile, setQuoteFile] = useState<File | null>(null);
  const hiddenQuoteFileInput = useRef<HTMLInputElement>(null);
  const [additionalNote, setAdditionalNote] = useState<string>("");

  const usersMap = useUsersByIds([order.primaryContactId, order.installerOnSiteLeadId, ...order.sharedWithIds]);
  const users = usersMap ? Object.values(usersMap) : [];

  const connections = useConnections(); // TODO: only get specific one? pass from parent?
  const installerName = connections?.find(c => c.id === order.installerId)?.name;

  // ------------------ //
  // --- Use Effect --- //
  // ------------------ //

  // Set order when prop changes
  useEffect(() => {
    setOrder(structuredClone(order_prop));
  }, [order_prop]);

  // ----------------- //
  // --- Variables --- //
  // ----------------- //

  // Can submit bool
  const canSubmit = [
    order.quote.value != null, // Has value (TODO: check valid number?)
    quoteFile, // Has quote file

    order.quote.agreements.deliveryDate?.distributor !== AgreementStatus.PENDING, // Delivery date agreement
    order.quote.agreements.material?.distributor !== AgreementStatus.PENDING, // Material agreement
    order.quote.agreements.amount?.distributor !== AgreementStatus.PENDING, // Amount agreement (happens when quote value is set)

    // If material agreement is rejected, material note required
    order.quote.agreements.material?.distributor !== AgreementStatus.REJECTED || materialNote, // Material note

    // If delivery date agreement is rejected, adjustments required
    //   If relative, need amount and unit
    order.quote.agreements.deliveryDate?.distributor === AgreementStatus.REJECTED ? (
      !order.requestedDelivery.relativeToInstall // Not relative
      || // OR
      (order.requestedDelivery.relativeToInstall.amount && order.requestedDelivery.relativeToInstall.unit) // Has amount and unit
    )
      : true,

    // If delivery date agreement is rejected, need delivery date adjustments
    order.quote.agreements.material?.distributor !== AgreementStatus.REJECTED
    || (
      order.requestedDelivery.deliveryDate
      && order.requestedDelivery.deliveryWindow.start
      && order.requestedDelivery.deliveryWindow.end
    )
  ].every(Boolean);

  // ----------------- //
  // --- Functions --- //
  // ----------------- //

  /**
   * Set the modal open state.
   * Resets order and state changes.
   */
  function setOpen(open: boolean) {
    setOrder(structuredClone(order_prop));
    setOpen_prop(open);
    handleQuoteFileRemove();
  }

  /**
   * Update the order's SO number on input
   */
  function handleSOChange(value) {
    setOrder((o) => {
      o.soNumber = value
      return o;
    })
  }

  /**
   * Clicks the hidden quote file input. This opens the file select dialog.
   */
  function handleQuoteFileClick() {
    hiddenQuoteFileInput.current.click();
  }

  /**
   * Grabs the file when a file is selected.
   */
  function handleQuoteFileChange(event) {
    if (event.target.files?.length) {
      setQuoteFile(event.target.files[0]);
    }
  }

  /**
   * Removes the quote file from the order.
   */
  function handleQuoteFileRemove() {
    setQuoteFile(null);
    hiddenQuoteFileInput.current.value = null;
  }

  /**
   * Submits the quote.
   * Sends POST to API with new quote and order data.
   */
  async function handleSubmit(event) {
    event.preventDefault();
    if (!canSubmit) return;

    setLoading(true); // Start spinner

    // Upload quote and get resulting S3 filepath
    let quoteFilePath = await uploadQuote();

    // TODO: show error message
    if (!quoteFilePath) {
      setLoading(false);
      return;
    }

    const body = {
      orderId: order._id,
      soNumber: order.soNumber || null,

      quoteFilePath: quoteFilePath,
      quoteValue: order.quote.value,

      deliveryDateAgreement: order.quote.agreements.deliveryDate.distributor,
      deliveryDate: order.quote.agreements.deliveryDate.distributor === AgreementStatus.REJECTED ? order.requestedDelivery : undefined,

      materialAgreement: order.quote.agreements.material.distributor,
      materialNote: order.quote.agreements.material.distributor === AgreementStatus.REJECTED ? materialNote : undefined,

      additionalNote: additionalNote
    }

    // Create quote
    try {
      var res = await UserManager.makeAuthenticatedRequest("/api/orders/quote", "POST", body);
    } catch (err) {
      // TODO: show error message
      console.error(err)
    }


    // TODO: show success message
    setOpen(false);
    setLoading(false);
  }

  /**
   * Handles uploading the current quote file to S3.
   * Returns the S3 filepath. 
   * Returns null if it fails.
   */
  async function uploadQuote(): Promise<string> {
    // TODO: improve file path? can do later
    let quoteFilePath = `${order.installerId}/${order.marketId}/${order._id}/quote/${quoteFile.name}`;

    await S3.upload(quoteFile, quoteFilePath);

    return quoteFilePath;
  }


  // TODO: form validation styling (red border and * label)
  return (
    <Modal open={open} setOpen={setOpen}>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col divide-y max-h-[80vh]"
      >

        {/* Header */}
        <div className="flex items-center justify-between pb-3">
          <div className="flex flex-col">
            <div className="text-base font-semibold">Quote / Sales Order</div>
            <div className="text-sm font-normal text-gray-500">Price Quote for products and materials</div>
          </div>
        </div>

        <div className="px-4 -mx-4 bg-gray-100 divide-y divide-gray-300 border-y-2 sm:-mx-6 sm:px-6">
          {/* Sharing */}
          <div className="flex gap-6 py-4">
            <div className="flex flex-col gap-2 grow">
              <div className="text-sm font-medium whitespace-nowrap">Submit to Installer</div>
              <div className="text-sm font-medium w-[min-content] whitespace-nowrap text-gray-700 bg-gray-50 border-gray-200 rounded-md border px-1 py-0.5">
                {installerName}
              </div>
            </div>
            <div className="flex flex-col gap-2 grow">
              <div className="text-sm font-medium">Shared With</div>
              {/* TODO: shared with */}
              <div className="flex flex-wrap gap-2">
                {users?.map(user => (
                  <div className="text-sm font-medium text-gray-700 bg-gray-50 border-gray-200 rounded-md border px-1 py-0.5" key={user._id}>
                    {user.email}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Order Details */}
          <div className="flex justify-between py-4">
            <div className="flex flex-col">
              <div className="text-sm font-medium">Order Name</div>
              <div className="text-sm font-normal text-gray-500">{order.name ?? <span className="italic">No Name</span>}</div>
            </div>
            <div className="flex flex-col">
              <div className="text-sm font-medium">Address</div>
              <div className="text-sm font-normal text-gray-500">
                <div>{order.orderAddress.line1}</div>
                {order.orderAddress.line2 && <div>{order.orderAddress.line2}</div>}
                <div>{order.orderAddress.city}, {order.orderAddress.state} {order.orderAddress.postalCode}</div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-sm font-medium">Contact Info</div>
              <div className="text-sm font-normal text-gray-500">
                <div>{order.contact?.name}</div>
                <div>{order.contact?.phone}</div>
                <div>{order.contact?.email}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Quote & SO */}
        <div className="flex justify-between">
          <div className="flex items-center justify-center py-4">
            <input
              type="file"
              onChange={handleQuoteFileChange}
              ref={hiddenQuoteFileInput}
              className="hidden"
            />
            {!quoteFile
              ? <Button
                variant="primary"
                onClick={handleQuoteFileClick}
              >
                <div className="flex items-center justify-center">
                  <PaperClipIcon className="w-6 h-6" />
                  <div>Upload Quote</div>
                </div>
              </Button>
              : <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center w-8 h-8 bg-gray-700 rounded-full">
                    <PaperClipIcon className="w-5 h-5 text-white" />
                  </div>
                  <p className="text-sm font-semibold text-gray-600">Quote</p>
                </div>
                <div className="text-sm font-medium text-gray-500">
                  {StringHelper.truncate(quoteFile.name, 16)}
                  {" "}
                  <a
                    className="font-semibold cursor-pointer text-primary-green hover:text-primary-green-700"
                    onClick={() => window.open(URL.createObjectURL(quoteFile))}
                  >
                    View
                  </a>
                </div>
                <div className="ml-3">
                  <TrashIcon
                    className="w-5 h-5 cursor-pointer text-primary-rose hover:text-primary-rose-800"
                    onClick={handleQuoteFileRemove}
                  />
                </div>
              </div>
            }
          </div>
          <div className="flex items-center gap-3">
            <div className="text-sm font-medium">Sale Order #</div>
            <Input
              type="text"
              placeholder="Optional"
              value={order.soNumber ?? ""}
              onChange={handleSOChange}
            />
          </div>
        </div>

        {/* Quote Agreement */}
        <AgreementInputRows
          order={order}
          originalOrder={order_prop}
          onChange={setOrder}
          materialNote={materialNote}
          setMaterialNote={setMaterialNote}
        />

        <div className="py-3 grid grid-cols-2 gap-6 overflow-y-scroll">
          <div>
            <div className="font-semibold text-sm">Notes</div>
            <OrderNotes order={order} />
          </div>
          <div className="">
            <div className="font-semibold text-sm">Add Note</div>
            <Input
              placeholder="Add a note..."
              type="textarea"
              value={additionalNote}
              onChange={setAdditionalNote}
            />
          </div>
        </div>

        {/* Submit/Cancel */}
        <div className="flex justify-end gap-2 pt-4">
          {
            loading ? <div className="flex items-center pr-7">
              <Spinner size={20} />
            </div> :
              <Button type="submit" variant="primary" disabled={!canSubmit}>Submit Quote</Button>
          }
          <Button variant="secondary" onClick={() => setOpen(false)}>Cancel</Button>
        </div>
      </form>
    </Modal>
  )
}
