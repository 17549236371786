import moment from 'moment';
import React, { ReactNode } from 'react';
import { MarketType } from 'src/contexts/forecast/history/ForecastHistoryContext.tsx';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import { LocationType } from 'src/hooks/data/locations/useLocations.ts';
import { AgreementStatus, Order, OrderStatus, OrderStatusLabels } from 'src/hooks/data/orders/useOrders.ts';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import classNames from 'src/tools/classNames';
import StringHelper from 'src/utils/stringHelper.ts';
import Button from '../input/Button.js';
import IconDropdown from '../input/IconDropdown.js';
import OrderAgreementIcons from './OrderAgreementIcons.tsx';
import { OrderAction } from './OrderList.tsx';

type Props = {
  order: Order;
  market?: MarketType;
  location?: LocationType;
  connection: { name: string, id: string };
  view: 'rows' | 'list';
  isSelected?: boolean;
  onClick?: () => void;
  onAction?: (action: OrderAction) => void;
}

/**
 * This component displays a single order in a row or list view.
 * Used in `OrderList`.
 * Row view has more data and larger items. List view is more compact.
 *
 * The order actions and its related enums and maps make it easy to define actions
 * based on user company type and order status. Sending an `OrderAction` to the
 * `onAction` callback will prompt `OrderList` to do that action on the order
 * for this row item.
 *
 * `SpecialStatusLabels`, `SpecialStatusVariant`, and `StatusActions` make it 
 * super easy to customize the row item (in style and action) based on order input. 
 */
export default function OrderRowItem({ order,
  market,
  location,
  connection,
  view,
  isSelected,
  onClick = () => { },
  onAction = () => { }
}: Props) {
  const user = useCurrentUser();
  const isInstaller = user?.company?.type === "installer";
  const isDistributor = user?.company?.type === "distributor";

  const deliveryDateString = getDeliveryDateString(order);
  const shortDateString = getDeliveryDateString(order, true);

  const installDateString = getInstallDateString(order);

  // ------------------------ //
  // --- Special Statuses --- //
  // ------------------------ //

  /**
    * Special status labels for different user types
    * This is used to override the default status labels
    * Useful for showing additional emphasis to the user
    * For example, for a distributor, "Needs Quote" instead 
    * of "Waiting for Quote" shows more urgency.
    */
  const SpecialStatusLabels = {
    "installer": {
      [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]: "Needs Approval"
    },
    "distributor": {
      [OrderStatus.WAITING_FOR_QUOTE]: "Needs Quote",
      [OrderStatus.CONFIRMED]: "Needs Pick Ticket",
      [OrderStatus.PACKAGED]: "Needs Driver"
    },
  }

  const waitingOnInstaller = order.quote?.agreements.deliveryDate.installer === AgreementStatus.PENDING
    || order.quote?.agreements.material.installer === AgreementStatus.PENDING
    || order.quote?.agreements.amount?.installer === AgreementStatus.PENDING;
  const waitingOnDistributor = order.quote?.agreements.deliveryDate.distributor === AgreementStatus.PENDING
    || order.quote?.agreements.material.distributor === AgreementStatus.PENDING
    || order.quote?.agreements.amount?.distributor === AgreementStatus.PENDING;

  /**
    * Special status variants for different user types
    * This is used to emphasize status badges with different colors.
    * For example, for a distributor, WAITING_FOR_QUOTE 
    * as orange/yellow shows more urgency as it pops out to the user.
    */
  const SpecialStatusVariant = {
    "installer": {
      [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]: MinorBadgeVariant.WARNING,
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]: waitingOnInstaller ? MinorBadgeVariant.WARNING : MinorBadgeVariant.DEFAULT,
    },
    "distributor": {
      [OrderStatus.WAITING_FOR_QUOTE]: MinorBadgeVariant.WARNING,
      [OrderStatus.CONFIRMED]: MinorBadgeVariant.WARNING,
      [OrderStatus.PACKAGED]: MinorBadgeVariant.WARNING,
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]: waitingOnDistributor ? MinorBadgeVariant.WARNING : MinorBadgeVariant.DEFAULT,
    },
  }

  /**
    * Actions specific to different user types
    * and specific statuses. For example, a distributor can
    * add a quote to an order that is WAITING_FOR_QUOTE.
    */
  const StatusActions = {
    "installer": {
      [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]:
        <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.APPROVE_ORDER);
          }}
        >
          Review
        </Button>,
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]:
        !waitingOnInstaller ? null : <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.APPROVE_ORDER);
          }}
        >
          Review
        </Button>,
    },
    "distributor": {
      [OrderStatus.WAITING_FOR_QUOTE]:
        <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.ADD_QUOTE);
          }}
        >
          Add Quote
        </Button>,
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]:
        !waitingOnDistributor ? null : <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.APPROVE_ORDER);
          }}
        >
          Review
        </Button>,
      [OrderStatus.CONFIRMED]:
        <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.ADD_PICK_TICKET);
          }}
        >
          Hand off to Packaging
        </Button>,
      [OrderStatus.PACKAGED]:
        <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.ASSIGN_TRUCK);
          }}
        >
          Assign Truck
        </Button>,
    }
  }

  // -------------- //
  // --- Consts --- //
  // -------------- //

  const statusLabel = SpecialStatusLabels[user?.company?.type]?.[order.status] ?? OrderStatusLabels[order.status];
  let statusVariant = SpecialStatusVariant[user?.company?.type]?.[order.status];
  if (statusVariant instanceof Function) statusVariant = statusVariant(order);
  if (!statusVariant) statusVariant = MinorBadgeVariant.DEFAULT;
  const statusAction = StatusActions[user?.company?.type]?.[order.status];

  // TODO: improve this. This ends up doing lots of requests but combined it fails both instead of individually
  // so if no quote, then bom doesn't work
  const bomLinkArr = useS3URLs(order.files.billOfMaterials.at(-1)?.filePath ? [order.files.billOfMaterials[0]?.filePath] : []);
  const quoteLinkArr = useS3URLs(order.quote?.file?.filePath ? [order.quote?.file?.filePath] : []);

  const bomLink = bomLinkArr?.length ? bomLinkArr[0].getObjectSignedUrl : null;
  const quoteLink = quoteLinkArr?.length ? quoteLinkArr[0].getObjectSignedUrl : null;

  const rowActions = [
    {
      label: "View Order",
      href: `../details/${order._id}`
    },
    {
      label: "Cancel Order",
      value: OrderAction.CANCEL_ORDER
    },
  ];

  return view === "rows" ? (

    // ----------------- //
    // --- ROWS VIEW --- //
    // ----------------- //
    <div
      data-disabled={isSelected}
      className={classNames(
        "group/row grid grid-cols-subgrid col-span-full cursor-pointer",

        // Padding
        "p-4",

        // Coloring
        // TODO: dark mode
        "bg-white hover:bg-gray-200 data-[disabled=true]:bg-primary-green-100 border border-200",
      )}
      onClick={onClick}
    >

      {/* Status and Name */}
      <div
        className={classNames(
          "text-sm font-medium flex flex-col justify-center",
        )}
      >
        <div className="flex">
          {/* TODO: add error flag */}
          <div className="flex flex-col justify-center gap-1.5">
            {/* Name */}
            <p className="text-sm font-semibold text-gray-600 line-clamp-1">{order.name ?? <span className="italic">No Name</span>}</p>
            {/* Address */}
            <p className="text-sm font-normal text-gray-600 line-clamp-1">{addressToString(order.orderAddress)}</p>
            {/* Contact Name */}
            <p className="text-sm font-normal text-gray-600 line-clamp-1">{order.contact?.name}</p>
          </div>
        </div>
        <MinorBadge variant={statusVariant}>{statusLabel}</MinorBadge>
      </div>

      {/* Metadata */}
      <div
        className={classNames(
          "text-gray-600 text-sm font-normal flex flex-col justify-center gap-2"
        )}
      >
        <div className="flex gap-2">
          {isInstaller && <MinorBadge>{market?.name ?? <span className="italic">No Market</span>}</MinorBadge>}
          {isDistributor && <MinorBadge>{location?.name ?? <span className="italic">No Location</span>}</MinorBadge>}
          <MinorBadge>{connection?.name}</MinorBadge>
        </div>
        <div className="flex flex-col gap-1">
          {/* TODO: TOA order */}
          <div><span className="font-semibold">Install Date: </span>{installDateString}</div>
          <div><span className="font-semibold">Delivery: </span>{deliveryDateString}</div>
        </div>
      </div>

      {/* Attachments & Quote */}
      <div className="flex flex-col gap-2">
        <OrderAgreementIcons order={order} />
        <div className="grid grid-cols-[auto,auto,auto,auto] gap-x-0.5 text-sm font-normal text-gray-600">
          <div className="text-sm font-semibold text-gray-600">BOM:</div>
          <p>
            {
              order.files.billOfMaterials ?
                <a
                  data-disabled={bomLink == null}
                  className="line-clamp-1 cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
                  href={bomLink ?? "#"}
                  target="_blank"
                >
                  {getFileName(order.files.billOfMaterials.at(-1)?.filePath)}
                </a> :
                "--"
            }
          </p>
          <div className="text-sm font-semibold text-gray-600">PO#:</div>
          <div className="whitespace-nowrap">{order.poNumber ?? "--"}</div>
          <div className="text-sm font-semibold text-gray-600">Quote:</div>
          <p>
            {
              order.quote?.file ?
                <a
                  data-disabled={quoteLink == null}
                  className="line-clamp-1 cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
                  href={quoteLink ?? "#"}
                  target="_blank"
                >
                  {getFileName(order.quote.file.filePath)}
                </a> :
                "--"
            }
          </p>
          <div className="text-sm font-semibold text-gray-600">SO#:</div>
          <div className="whitespace-nowrap">{order.soNumber ?? "--"}</div>
          <div className="text-sm font-semibold text-gray-600">Amount:</div>
          <div>{order.quote?.value ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(order.quote.value) : "--"}</div>
        </div>
      </div>

      {/* Actions */}
      {/* TODO: switch to flex? */}
      <div className="grid grid-cols-[auto,auto] gap-y-1 gap-x-3 place-items-center">
        {/* TODO: implement actions */}
        {/* Chat */}
        <div></div>
        {/* <button><ChatBubbleLeftRightIcon className="w-6 h-6" /></button> */}
        {/* Other */}
        <div onClick={e => e.stopPropagation()}>
          <IconDropdown
            options={rowActions}
            onSelected={(s: { label: string, value: any }) => {
              onAction(s.value as OrderAction);
            }}
          />
        </div>

        <div className="col-span-2">
          {statusAction}
        </div>
      </div>
    </div >
  )

    // ----------------- //
    // --- LIST VIEW --- //
    // ----------------- //
    : (
      // TODO: select state
      <div
        data-disabled={isSelected}
        className={classNames(
          "group/row grid grid-cols-subgrid",
          "col-span-full bg-white items-center py-1 px-2",
          "bg-white hover:bg-gray-200 data-[disabled=true]:bg-primary-green-100 border border-200",
          "cursor-pointer",
          "text-sm",
        )}
        onClick={onClick}
      >
        {/* TODO: add TOA ID */}
        <div className="line-clamp-2">{order.name ?? <span className="italic">No Name</span>}</div>
        <div className="flex flex-wrap gap-2">
          <MinorBadge variant={statusVariant}>{statusLabel}</MinorBadge>
          {isInstaller && <MinorBadge>{market?.name ?? <span className="italic">No Market</span>}</MinorBadge>}
          {isDistributor && <MinorBadge>{location?.name ?? <span className="italic">No Location</span>}</MinorBadge>}
          <MinorBadge>{connection.name}</MinorBadge>
        </div>
        <div>{shortDateString}</div>
        <div className=""><OrderAgreementIcons order={order} small /></div>
        <div className="flex items-center justify-end">
          <p className="-my-1">{statusAction}</p>
          <div onClick={e => e.stopPropagation()} className="flex items-center justify-end">
            <IconDropdown
              options={rowActions}
              onSelected={(s: { label: string, value: any }) => {
                onAction(s.value as OrderAction);
              }}
            />
          </div>
        </div>
      </div>
    )
}

/**
 * Returns a formatted string for the delivery date and time range
 * of the given order object.
 * Format examples:
 * - if not short: "Monday 1/2 @ 1pm - 3pm"
* - if short: "1/2 1-3pm"
 */
export function getDeliveryDateString(order: Order, short: boolean = false): string {
  let date = moment(order.requestedDelivery.deliveryDate).utc(); // UTC to ignore time
  let startTime = moment(order.requestedDelivery.deliveryWindow.start);// TODO: we'll need to handle timezones better with these time ranges
  let endTime = moment(order.requestedDelivery.deliveryWindow.end);

  const dateFormat = !short ? 'ddd M/D' : "M/D";
  const timeFormat = !short ? 'h:mma' : 'ha';

  let dateStr = date.format(dateFormat);
  let startTimeStr = startTime.format(timeFormat);
  let endTimeStr = endTime.format(timeFormat);

  return !short
    ? `${dateStr} @ ${startTimeStr}-${endTimeStr}`
    : `${dateStr} ${startTimeStr}-${endTimeStr}`
}

/**
 * Returns a formatted string for the installation date of the given order object.
 */
function getInstallDateString(order: Order) {
  if (!order.installationDate) return "--";

  let date = moment(order.installationDate).utc() // UTC to ignore time
  return date.format("ddd M/D")
}

type MinorBadgeProps = {
  children: ReactNode;
  variant?: MinorBadgeVariant;
}
enum MinorBadgeVariant {
  DEFAULT = "DEFAULT",
  WARNING = "WARNING",
}

/**
  * Minor badge for displaying things like tags.
  * Used for status, market/location, and connection.
  */
export function MinorBadge({
  children,
  variant = MinorBadgeVariant.DEFAULT,
}: MinorBadgeProps): JSX.Element {

  let color = "";
  switch (variant) {
    case MinorBadgeVariant.WARNING:
      color = "bg-orange-100 text-orange-700 border-orange-200";
      break;
    case MinorBadgeVariant.DEFAULT:
    default:
      color = "bg-gray-50 text-gray-700 border-gray-200";
      break;
  }

  return <div
    className={classNames(
      "border text-sm font-medium rounded w-[max-content] px-1",
      color
    )}
  >{children}</div>
}

/**
  * Converts an address object to a string.
  * No new lines are added.
  */
export function addressToString(address: { line1: string, line2?: string, city: string, state: string, postalCode: number }): string {
  const { line1, line2, city, state, postalCode } = address
  return `${line1}, ${line2 ? line2 + ", " : ""}${city}, ${state} ${postalCode}`

}

/**
  * Handles getting a file name from a file path.
  * Truncates at 20 characters by default.
  */
function getFileName(filePath: string, truncate: boolean = true): string {
  let fileName = filePath.split("/").pop();
  if (truncate) {
    fileName = StringHelper.truncate(fileName, 20);
  }
  return fileName
}
