import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import FloCard from "../components/deprecated/FloCard";
import Spinner from "../components/Spinner";
import UserManager from "../tools/UserManager";
import Button from "../components/input/Button";
import axios from "axios";
import Card from "../components/Card";
import StyleThemeSelector from "../components/UserPage/StyleThemeSelector.tsx";
import TopBar from "../components/nav/TopBar";
import Input from "../components/input/Input";

function UserInvite() {
  const [message, setMessage] = useState(null);

  function createInvite(event) {
    event.preventDefault();

    const email = event.target.email.value;

    UserManager.makeAuthenticatedRequest("/api/invite/create", "POST", {
      email,
    }).then((res) => {
      setMessage(
        res.data.status === "error" ? (
          res.data.errorBreakdown ? (
            <div>
              {res.data.errorBreakdown.message} <span className="font-semibold">{res.data.errorBreakdown.inviteEmail}</span>
            </div>
          ) : (
            res.data.error
          )
        ) : (
          <div>
            Invite sent to <span className="font-semibold">{email}.</span>
          </div>
        )
      );
    });
  }

  return (
    <div>
      <form
        className="flex flex-col items-start gap-3 p-1"
        onSubmit={createInvite}
      >
        <div className="flex flex-row items-center gap-3">
          <Input
            type="email"
            required
            id="email"
            name="email"
            placeholder="Email"
          />
          <Button type="submit">
            Invite
          </Button>
        </div>

        <div className="w-full">{message}</div>
      </form>
    </div>
  );
}

export default function UserPage() {
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [copied, setCopied] = useState(false);

  function logout() {
    UserManager.logout();
  }

  useEffect(() => {
    UserManager.getUser()
      .then((user) => {
        setUser(user.userData);

        // Get company
        if (user.userData.company) {
          UserManager.makeAuthenticatedRequest(
            "/api/company/findmy",
            "GET"
          ).then((res) => {
            setCompany(res.data.company);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="flex flex-col gap-2 dark:text-white">
      {/* Top Bar */}
      <TopBar>User</TopBar>

      {/* Body */}
      <div className="inset-0 flex flex-col flex-1 p-6 pt-2 space-y-2">
        <div className="flex flex-wrap items-start gap-3">
          {/* Info card */}
          <Card className="flex flex-col text-base font-normal">
            <div className="text-lg font-semibold">Info</div>
            <div>
              Name: {user.firstName} {user.lastName}
            </div>
            <div>Email: {user.email}</div>
            <div>Role: {user?.company?.role ? user.company.role.charAt(0).toUpperCase() + user.company.role.slice(1) : "None"}</div>
          </Card>

          {/* Company card // TODO: make it not just installers */}
          <Card className="flex flex-col text-base font-normal">
            <div className="text-lg font-semibold">Company</div>
            <div>Name: {company.name}</div>
            <div className="capitalize">Type: {user.company?.type}</div>
            {/* TODO: implement <div>
              Distributors:{" "}
              {company.distributors?.length > 0
                ? company.distributors.map((d) => d.name).join(", ")
                : "None"}
            </div> */}
          </Card>

          {/* Invite card */}
          {user.company?.role === "admin" && (
            <Card className="flex flex-col text-base font-normal">
              <div className="text-lg font-semibold">Invite Users</div>
              <UserInvite />{" "}
            </Card>
          )}

          {/* Dark Mode Selector */}
          {/* // TODO: uncomment when dark mode is ready */}
          {/* <Card>
            <div className="flex flex-col gap-1">
              <div className="text-lg font-semibold">Theme</div>
              <StyleThemeSelector />
            </div>
          </Card> */}

          {/* Logout button */}
          <Card>
            <Button onClick={logout}>Log Out</Button>
          </Card>
        </div>
      </div>
    </div>
  );
}
