import UserManager from "../UserManager"

export type S3File = {
  getObjectSignedUrl: string,
  deleteObjectSignedUrl: string,
}

/**
  * S3 bucket name where files are stored
  */
const S3_BUCKET = "toa-order-attachments";

/**
 * Helper class for interacting with S3
 */
export default class S3 {

  /**
   * Gets presigned URLs for the given filepaths
   * Returns empty if no filepaths are provided or if no files are found
   */
  static async get(filepaths: string[], signal: AbortSignal = null): Promise<S3File[]> {

    let url = "/api/s3/get-presigned-urls?"
    url += `bucket=${S3_BUCKET}&`;
    url += `objectKeys=${filepaths.join(",")}`;
    try {
      var res = await UserManager.makeAuthenticatedRequest(url, "GET", null, { signal: signal })
    } catch (error) {
      console.error(error)
    }

    if (res.data.status === "ok" && res.data.presignedUrls?.length) {
      return res.data.presignedUrls
    }

    return []
  }

  /**
   * Upload the given file to S3 at the given filename/path
   */
  static async upload(file: File, fileName: string): Promise<boolean> {
    // Get upload URL
    try {
      var response = await UserManager.makeAuthenticatedRequest(
        `/api/s3/get-upload-url?bucket=toa-order-attachments&fileName=${fileName}`,
        'GET',
      )
    } catch (error) {
      console.error(error)
      return false;
    }

    // Check if the response is successful
    if (response.data.status !== 'ok') {
      return false;
    }

    // Upload file to S3
    const putObjectSignedUrl = response.data.putObjectSignedUrl
    try {
      await fetch(putObjectSignedUrl, {
        method: 'PUT',
        body: file
      })
    } catch (error) {
      console.error(error)
      return false;
    }

    return true;
  }
}
